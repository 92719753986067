import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ListFilter, PartialList, Response, StdModel } from './models';

export const EmailTypes = {
  RESET_PASSWORD: { value: 'RESET_PASSWORD', text: 'パスワードリセット' },
  CREATE_NEW_USER: { value: 'CREATE_NEW_USER', text: 'ユーザー追加' },
  ROYALTY_NOTIF: { value: 'ROYALTY_NOTIF', text: '印税明細送付' },
  PAYMENT_NOTIF: { value: 'PAYMENT_NOTIF', text: '支払明細送付' },
  PAYMENT_RECORD_NOTIF: { value: 'PAYMENT_RECORD_NOTIF', text: '支払調書送付' },
};
export type EmailTypes = typeof EmailTypes[keyof typeof EmailTypes];

export const FeedbackStatus = {
  UNRECEIVED: { value: 'UNRECEIVED', text: '未受信' },
  PARTIALLY_RECEIVED: { value: 'PARTIALLY_RECEIVED', text: '一部受信済み' },
  RECEIVED: { value: 'RECEIVED', text: '受信済み' },
};
export type FeedbackStatus = typeof FeedbackStatus[keyof typeof FeedbackStatus];

export const DeliveryStatus = {
  UNDELIVERED: { value: 'UNDELIVERED', text: '未配信' },
  PARTIALLY_DELIVERED: { value: 'PARTIALLY_DELIVERED', text: '一部配信済み' },
  DELIVERED: { value: 'DELIVERED', text: '配信済み' },
};
export type DeliveryStatus = typeof DeliveryStatus[keyof typeof DeliveryStatus];

export const DeliveryStatusForFilter = {
  UNDELIVERED: { value: 'UNDELIVERED', text: '未配信' },
  DELIVERED: { value: 'DELIVERED', text: '配信済み' },
};
export type DeliveryStatusForFilter =
  typeof DeliveryStatusForFilter[keyof typeof DeliveryStatusForFilter];

export const AwsSnsNotificationType = {
  Bounce: { value: 'Bounce', text: 'バウンス' },
  Complaint: { value: 'Complaint', text: '苦情' },
  Delivery: { value: 'Delivery', text: '配信' },
};
export type AwsSnsNotificationType =
  typeof AwsSnsNotificationType[keyof typeof AwsSnsNotificationType];
export interface EmailSendingHistory extends StdModel {
  email_type?: string;
  transaction_key?: string;
  email_subject?: string;
  send_status?: string;
  feedback_status?: string;
  delivery_status?: string;
  send_at?: Date;
  send_by?: string;
  to_address?: string;
  aws_ses_message_id?: string;
  to_key?: string;
  aws_ses_status?: string;
  payee_code?: string;
  name_of_to_key?: string;
  undelivered_detail?: string;
  name_of_send_by?: string;
  last_notification_at?: Date;

  email_type_text?: string;
  feedback_status_text?: string;
  delivery_status_text?: string;
}

export interface SendEmailByAddress extends StdModel {
  address?: string;
  delivery_result?: string;
  aws_sns_notification_type?: string;
  aws_sns_bounce_type?: string;
  aws_sns_bounce_sub_type?: string;
  undelivered_detail?: string;
  last_notification_at?: Date;
}
export interface EmailSendingHistoryFilter extends ListFilter {
  to_address?: string;
  payee_code?: string;
  send_at_yyyymmdd_from?: string;
  send_at_yyyymmdd_to?: string;
  email_type?: string;
  delivery_status_for_filter?: string;
}

@Injectable()
export class EmailSendingHistoriesService {
  constructor(private http: HttpClient) {}

  listByFilter(
    d: EmailSendingHistoryFilter
  ): Observable<Response<PartialList<EmailSendingHistory>>> {
    let params = new HttpParams()
      .append('limit', String(d.limit))
      .append('offset', String(d.offset));
    d.to_address && (params = params.append('to_address', d.to_address));
    d.payee_code && (params = params.append('payee_code', d.payee_code));
    d.send_at_yyyymmdd_from &&
      (params = params.append(
        'send_at_yyyymmdd_from',
        d.send_at_yyyymmdd_from
      ));
    d.send_at_yyyymmdd_to &&
      (params = params.append('send_at_yyyymmdd_to', d.send_at_yyyymmdd_to));
    d.email_type && (params = params.append('email_type', d.email_type));
    d.delivery_status_for_filter &&
      (params = params.append(
        'delivery_status_for_filter',
        d.delivery_status_for_filter
      ));
    return this.http
      .get<Response<PartialList<EmailSendingHistory>>>(
        `/authorized/email-sending-histories/resources`,
        { params }
      )
      .pipe(tap((res) => this.formatResponse(res.data.list)));
  }

  private formatResponse(resp: EmailSendingHistory[]) {
    if (!resp) {
      return;
    }
    resp.forEach((e) => {
      e.email_type_text = EmailTypes[e.email_type]
        ? EmailTypes[e.email_type].text
        : e.email_type;
      e.feedback_status_text = FeedbackStatus[e.feedback_status]
        ? FeedbackStatus[e.feedback_status].text
        : e.feedback_status;
      e.delivery_status_text = DeliveryStatus[e.delivery_status]
        ? DeliveryStatus[e.delivery_status].text
        : e.delivery_status;
      e.to_address = e.to_address.replace(/,/g, '\n');
    });
  }

  listFeedbacks(id: string): Observable<Response<SendEmailByAddress[]>> {
    let params = new HttpParams().append('id', id);
    return this.http.get<Response<SendEmailByAddress[]>>(
      `/authorized/email-sending-histories/feedbacks`,
      { params }
    );
  }
}
