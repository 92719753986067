<form [formGroup]="form" autocomplete="off">

  <div class="entry">
    <p>
      新しいパスワードを設定してください。
      <br>
      ※８文字以上で、半角英字・半角数字・記号を各１文字以上含めてください。
    </p>

    <mat-form-field appearance="outline">
      <mat-label>パスワード</mat-label>
      <input matInput placeholder="パスワード" [type]="passwordVisible ? 'text' : 'password'" formControlName="password">
      <a mat-icon-button matSuffix (click)="passwordVisible = !passwordVisible">
        <mat-icon>{{passwordVisible ? 'visibility' : 'visibility_off'}}</mat-icon>
      </a>
      <mat-error *ngIf="password.hasError('required')">パスワードを入力してください</mat-error>
      <mat-error *ngIf="password.hasError('invalid')">要件を満たすパスワードを入力してください</mat-error>
    </mat-form-field>

    <p class="warning" *ngIf="failed">
      リクエストを受け付けることができませんでした<br>管理者へお問い合わせください
    </p>
  </div>

  <div class="action">
    <span></span>
    <button mat-raised-button color="primary" [disabled]="processing" (click)="onSubmit()">設定</button>
  </div>
</form>