import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen, tap } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { AuthService } from './auth.service';



@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

  constructor(
    private errorHandler: ErrorHandler,
    private auth: AuthService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.token;
    const customHeaders = token ? req.headers.set('Authorization', `Bearer ${token}`) : req.headers;

    const customReq = req.clone({
      url: `${environment.apiEndpoint}${req.url}`,
      headers: customHeaders
    });

    return next.handle(customReq).pipe(
      retryWhen(errs => errs.pipe(
        concatMap((err, count) => {
          if (count < 2 && err.status === 500) {
            return of(err.status);
          }

          return throwError(err);
        }),
        delay(300)
      )),
      tap(
        (_: HttpEvent<any>) => { },
        (err: HttpErrorResponse) => this.handleError(err)
      )
    );
  }

  private handleError(err: HttpErrorResponse) {
    if (err.error instanceof ErrorEvent) {
      console.error('An error occurred:', err.error.message);
      this.errorHandler.handleError(err);
    } else {
      console.error(`Backend returned code ${err.status}`, err);
      switch (err.status) {
        case 400:
          // BadRequest should be handled by service.
          break;
        case 401:
          // Unauthorized let state to unauthenticated forcibly.
          this.auth.logoutForcibly();
          break;
        case 404:
          // NotFound should be handled by service.
          break;
        default:
          this.errorHandler.handleError(err);
          break;
      }
    }
  }
}