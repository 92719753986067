import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileUploadParams, ListFilter, PartialList, Response, StdModel, StdMst } from './../models';

export interface EditingCost extends StdModel {
  editing_cost_import_id?: string;
  payment_detail_id?: string;
  booked_yyyymmdd?: string;
  payment_due_yyyymm?: string;
  name?: string;
  quantity?: number;
  unit_price?: number;
  tax_excluded_amount?: number;
  consumption_tax?: number;
  tax_included_amount?: number;
  payee_code?: string;
  ebook_product_code?: string;
  editing_cost_type_code?: string;
  accounting_code?: string;
  accounting_name?: string;

  payee_name?: string;
  ebook_product_name?: string;
  editing_cost_type_name?: string;
}

export interface EditingCostFilter extends ListFilter {
  booked_yyyymmdd_from?: string;
  booked_yyyymmdd_to?: string;
  editing_cost_type_code?: string;
  editing_cost_type_name?: string;
}

export interface EditingCostImportProcessParams {
  import_id?: string;
  upload_params?: FileUploadParams;
}

export interface EditingCostImportProcessResult {
  worker_id?: string;
  import_id?: string;
}

export interface EditingCostImport {
  id?: string;
  status?: string;
  issue_cnt?: number;
}

export interface EditingCostImportIssue {
  id?: string;
  editing_cost_import_id?: string;
  row_index?: number;
  column_index?: number;
  column_name?: string;
  issue?: string;
}

export interface EditingCostType extends StdMst {
  name?: string;
}

@Injectable()
export class EditingCostsService {

  constructor(
    private http: HttpClient,
  ) { }

  listByFilter(d: EditingCostFilter): Observable<Response<PartialList<EditingCost>>> {
    let params = new HttpParams()
      .append('limit', String(d.limit))
      .append('offset', String(d.offset));
    d.booked_yyyymmdd_from && (params = params.append('booked_yyyymmdd_from', d.booked_yyyymmdd_from));
    d.booked_yyyymmdd_to && (params = params.append('booked_yyyymmdd_to', d.booked_yyyymmdd_to));
    d.editing_cost_type_code && (params = params.append('editing_cost_type_code', d.editing_cost_type_code));
    return this.http.get<Response<PartialList<EditingCost>>>(`/authorized/editing-costs/resources`, { params });
  }

  delete(id: string): Observable<Response<any>> {
    return this.http.delete<Response<any>>(`/authorized/editing-costs/resources/${id}`);
  }

  getFileUploadURL(): Observable<Response<EditingCostImportProcessParams>> {
    return this.http.post<Response<EditingCostImportProcessParams>>(`/authorized/editing-costs/upload-url`, {});
  }

  postFileImportProcess(filePath: string): Observable<Response<EditingCostImportProcessResult>> {
    return this.http.post<Response<EditingCostImportProcessResult>>(`/authorized/editing-costs/process`, { file_path: filePath });
  }

  getFileDownloadURL(): Observable<Response<string>> {
    return this.http.get<Response<string>>(`/authorized/editing-costs/export/download-url`);
  }

  postFileExportProcess(d: EditingCostFilter): Observable<Response<string>> {
    return this.http.post<Response<string>>(`/authorized/editing-costs/export/process`, d);
  }

  getImportRecordBy(importId: string): Observable<Response<EditingCostImport>> {
    return this.http.get<Response<EditingCostImport>>(`/authorized/editing-costs/imports/${importId}`);
  }

  listImportIssuesBy(importId: string, limit: number, offset: number): Observable<Response<PartialList<EditingCostImportIssue>>> {
    let params = new HttpParams()
      .append('editing_cost_import_id', importId)
      .append('limit', String(limit))
      .append('offset', String(offset));
    return this.http.get<Response<PartialList<EditingCostImportIssue>>>(`/authorized/editing-costs/issues`, { params });
  }

  emptyType(): EditingCostType {
    return {
      code: null,
      name: null
    };
  }

  listAllTypes(): Observable<Response<EditingCostType[]>> {
    return this.http.get<Response<EditingCostType[]>>(`/authorized/editing-costs/types`);
  }

  postType(d: EditingCostType): Observable<Response<any>> {
    return this.http.post<Response<any>>(`/authorized/editing-costs/types`, [d]);
  }

  deleteType(d: EditingCostType): Observable<Response<any>> {
    return this.http.delete<Response<any>>(`/authorized/editing-costs/types/${d.code}`);
  }
}
