import { Component, ErrorHandler, NgZone, EventEmitter, OnInit, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ApplicationErrorHandler implements ErrorHandler {

  onError = new EventEmitter<any>();

  constructor(private ngZone: NgZone) { }

  handleError(error: Error | HttpErrorResponse) {
    console.error('ERROR!', error);

    let errMsg;
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        console.log('401 will be logged out.');
        return;
      }
      errMsg = {
        heading: '通信エラー',
        detail: error.status === 0 ? '通信環境をご確認ください。' : error.message
      };
    } else {
      errMsg = {
        heading: 'エラー',
        detail: error.stack ? error.stack : (error.message ? error.message : 'No detailed error message...')
      };
    }

    this.ngZone.run(() => this.onError.emit(errMsg));
  }
}

@Component({
  selector: 'application-error',
  templateUrl: './application-error.component.html',
  styleUrls: ['./application-error.component.scss'],
})
export class ApplicationErrorComponent implements OnInit {

  errMsg: any;

  constructor(private errorHandler: ErrorHandler) { }

  ngOnInit() {
    (this.errorHandler as ApplicationErrorHandler).onError.subscribe(errMsg => {
      this.errMsg = errMsg;
    });
  }
}
