import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'authenticator',
  templateUrl: './authenticator.component.html',
  styleUrls: ['./authenticator.component.scss'],
})
export class AuthenticatorComponent implements OnInit {

  activeFormName = 'UserPasswordForm';

  constructor() { }

  ngOnInit() { }

  onNextForm(event) {
    this.activeFormName = event;
  }

}
