
export class ArrayUtils {

  static toChunks<T>(src: T[], size: number = 1000): T[][] {
    if (!src || src.length === 0) {
      return [];
    }

    const chunks = [];
    let chunk = [];
    for (let i = 0; i < src.length; i++) {
      chunk.push(src[i]);
      if (chunk.length >= size) {
        chunks.push(chunk);
        chunk = [];
      }
    }
    if (chunk.length > 0) {
      chunks.push(chunk);
    }
    return chunks;
  }
}