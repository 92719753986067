import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from './users.service';
import { Response } from './models';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RolesService {

  constructor(
    private http: HttpClient,
  ) { }

  listRoles(): Observable<Response<Role[]>> {
    return this.http.get<Response<Role[]>>(`/authorized/roles`);
  }

  delete(roleId: string): Observable<Response<any>> {
    return this.http.delete<Response<any>>(`/authorized/roles/${roleId}`);
  }
}
