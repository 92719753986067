import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService, PaswordResetResult } from './../../auth.service';


@Component({
  selector: 'password-forgot-form',
  templateUrl: './password-forgot-form.html',
  styleUrls: ['./form.scss']
})
export class PasswordForgotForm implements OnInit {

  @Output() next = new EventEmitter<string>();

  form: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  processing: boolean;
  result: PaswordResetResult;

  constructor(
    private auth: AuthService,
  ) { }

  get email() { return this.form.get('email'); }

  ngOnInit() {
  }

  onGoToLoginClick() {
    this.next.emit('UserPasswordForm');
  }

  onSubmit() {
    Object.keys(this.form.controls).forEach(k => this.form.controls[k].markAsTouched());
    if (this.form.invalid) {
      return;
    }

    this.processing = true;
    this.result = null;

    this.auth
      .forgotPassword(this.form.controls.email.value)
      .subscribe({
        next: (res: PaswordResetResult) => {
          this.result = res;
          switch (res) {
            case PaswordResetResult.ResetMailSent:
              this.next.emit('PasswordResetForm');
              break;
            case PaswordResetResult.UserNotFound:
              this.email.setErrors({ invalid: true });
              break;
            default:
              break;
          }
        },
        error: _ => {
          this.result = PaswordResetResult.Failed;
          this.processing = false;
        },
        complete: () => {
          this.processing = false;
        }
      });
  }
}