import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PartialList, Response } from '../models';

export interface Bank {
  code?: string;
  name?: string;
  kana?: string;
  name_halfwidth?: string;
}

@Injectable()
export class BanksService {

  constructor(
    private http: HttpClient,
  ) { }

  listAll(): Observable<Response<PartialList<Bank>>> {
    return this.http.get<Response<PartialList<Bank>>>(`/authorized/banks`).pipe(
      tap(res => res.data.list)
    );
  }
}
