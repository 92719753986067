import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatModule } from './../../mat.module';
import { MenusComponent } from './app-menus/menus.component';
import { EnvelopeComponent } from './envelope.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatModule,
        PortalModule,
        OverlayModule,
    ],
    declarations: [
        EnvelopeComponent,
        MenusComponent,
    ],
    providers: [],
    exports: []
})
export class EnvelopeModule { }