import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatModule } from '../mat.module';

import { EnvelopeModule } from './envelope/envelope.module';
import { NotFoundPage } from './not-found/not-found.page';
import { ForbiddenPage } from './forbidden/forbidden.page';

@NgModule({
  imports: [
    RouterModule,
    EnvelopeModule,
    MatModule,
  ],
  declarations: [
    NotFoundPage,
    ForbiddenPage,
  ],
  providers: [
  ]
})
export class BaseModule { }