import { BusinessLogicError } from './constants';

/**
 * ルーティング設定データ。
 */
export interface RouteData {
  title: string;
  link?: string;
  parents?: RouteData[];
}

/**
 * APIのレスポンスのひな型。
 */
export interface Response<T> {
  success: boolean;
  data: T;
}

/**
 * 標準的なデータのモデル。
 */
export interface StdModel {
  id?: string;
  created_at?: Date;
  created_by?: string;
  updated_at?: string;
  updated_by?: string;
}

/**
 * 標準的なマスタのモデル。
 */
export interface StdMst {
  code?: string;
  created_at?: Date;
  created_by?: string;
  updated_at?: string;
  updated_by?: string;
}

/**
 * 一覧画面向けの部分的なリストのひな型。
 */
export interface PartialList<T> {
  numOfTotal?: number;
  num_of_total?: number;
  list: T[];
}

/**
 * 一覧画面の絞り込み条件のひな型。
 */
export interface ListFilter {
  limit?: number;
  offset?: number;
}

/**
 * ファイルをS3へアップロードするためのパラメータ。
 */
export interface FileUploadParams {
  access_key_id: string;
  secret_access_key: string;
  session_token: string;
  bucket: string;
  key: string;
}

export interface SimpleBusinessError {
  error: string;
  detail: null;
}

export interface FailedResponseData {
  error: BusinessLogicError;
  detail: IncorrectValidationResultsDetail[];
}
/**
 * APIのバリデーションエラーのレスポンスの詳細部分。
 *
 * #Response
 * {
 *   success: false,
 *   data: {
 *     error: 'incorrect validation results',
 *     detail: #IncorrectValidationResultsDetail
 *   }
 * }
 *
 */
export interface IncorrectValidationResultsDetail {
  target_key?: string;
  issue_items?: {
    item_key?: string;
    issues?: string[];
  }[];
}

/**
 * 端数処理の定義
 */
export const RoundingType = {
  ROUND: { id: 'ROUND', text: '四捨五入' },
  CEIL: { id: 'CEIL', text: '切り上げ' },
  FLOOR: { id: 'FLOOR', text: '切り捨て' },
} as const;
export type RoundingType = (typeof RoundingType)[keyof typeof RoundingType];

/**  印税契約マスタなどで使うテナントのデフォルト値を表すコード */
export const DEFAULT_CODE = 'DEFAULT';

/** Eメール送信対象のオプション */
export const EMAIL_SEND_TARGET_OPTIONS = {
  UNSENT: '未送信のみ',
  ALL: '全て',
};

export const EmailSendTargetOption = {
  UNSENT: { id: 'UNSENT', text: '未送信のみ' },
  ALL: { id: 'ALL', text: '全て' },
};
export type EmailSendTargetOption =
  (typeof EmailSendTargetOption)[keyof typeof EmailSendTargetOption];

/** 支払方法 */
export const PAYMENT_METHODS = {
  DOMESTIC_ACCOUNTS: '国内口座',
  FOREIGN_ACCOUNTS: '海外口座',
  CASH: '現金',
  OTHER: 'その他',
};

export const PaymentMethod = {
  DOMESTIC_ACCOUNTS: { id: 'DOMESTIC_ACCOUNTS', text: '国内口座' },
  FOREIGN_ACCOUNTS: { id: 'FOREIGN_ACCOUNTS', text: '海外口座' },
  CASH: { id: 'CASH', text: '現金' },
  OTHER: { id: 'OTHER', text: 'その他' },
};
export type PaymentMethod = (typeof PaymentMethod)[keyof typeof PaymentMethod];

/** 銀行の口座種別 */
export const BANK_ACCOUNT_TYPES = {
  1: '普通',
  2: '当座',
};

export const BankAccountType = {
  SAVING_ACCOUNT: { id: 1, text: '普通' },
  CHECKING_ACCOUNT: { id: 2, text: '当座' },
};
export type BankAccountType =
  (typeof BankAccountType)[keyof typeof BankAccountType];

/** 通知系の送信タイプ */
export const NOTIFICATION_TYPES = {
  1: '不要',
  2: '郵送',
  3: 'メール',
  4: 'その他',
};

export const NotificationType = {
  UNNECESSARY: { id: 1, text: '不要' },
  MAIL: { id: 2, text: '郵送' },
  EMAIL: { id: 3, text: 'メール' },
  OTHERS: { id: 4, text: 'その他' },
};
export type NotificationType =
  (typeof NotificationType)[keyof typeof NotificationType];

/** 源泉徴収有無 */
export const NEEDS_WITHHOLDING_TAX = {
  NEED: '有',
  NOT_NEED: '無',
};
export const NeedsWithholdingTax = {
  NEED: { id: 'NEED', text: '有' },
  NOT_NEED: { id: 'NOT_NEED', text: '無' },
};
export type NeedsWithholdingTax =
  (typeof NeedsWithholdingTax)[keyof typeof NeedsWithholdingTax];

/** 消費税有無 */
export const NEEDS_CONSUMPTION_TAX = {
  NEED: '有',
  NOT_NEED: '無',
};
export const NeedsConsumptionTax = {
  NEED: { id: 'NEED', text: '有' },
  NOT_NEED: { id: 'NOT_NEED', text: '無' },
};
export type NeedsConsumptionTax =
  (typeof NeedsConsumptionTax)[keyof typeof NeedsConsumptionTax];

/** 個人法人区分 */
export const PERSONALITY_FLGS = {
  1: '個人',
  2: '法人',
};

export const PersonalityFlg = {
  INDIVIDUAL: { id: 1, text: '個人' },
  CORPORATION: { id: 2, text: '法人' },
};
export type PersonalityFlg =
  (typeof PersonalityFlg)[keyof typeof PersonalityFlg];

/** 居住者区分 */
export const RESIDENT_TYPES = {
  RESIDENT: '居住者',
  NON_RESIDENT: '非居住者',
};

export const ResidentType = {
  RESIDENT: { id: 'RESIDENT', text: '居住者' },
  NON_RESIDENT: { id: 'NON_RESIDENT', text: '非居住者' },
};
export type ResidentType = (typeof ResidentType)[keyof typeof ResidentType];

/** 支払処理停止 */
export const IS_PAYMENT_PROCESS_STOPPED = {
  PROCESS_STOPPED: '停止',
  PROCESS_UNSTOPPED: '',
};
export const IsPaymentProcessStopped = {
  PROCESS_STOPPED: { id: 'PROCESS_STOPPED', text: '停止' },
  PROCESS_UNSTOPPED: { id: 'PROCESS_UNSTOPPED', text: '' },
};
export type IsPaymentProcessStopped =
  (typeof IsPaymentProcessStopped)[keyof typeof IsPaymentProcessStopped];

/** 支払閾値設定 */
export const PAYMENT_THRESHOLD_SETTINGS = {
  TENANT_SETTING: '基本設定',
  NO_THRESHOLD: '閾値なし',
};

export const PaymentThresholdSetting = {
  TENANT_SETTING: { id: 'TENANT_SETTING', text: '基本設定' },
  NO_THRESHOLD: { id: 'NO_THRESHOLD', text: '閾値なし' },
};
export type PaymentThresholdSetting =
  (typeof PaymentThresholdSetting)[keyof typeof PaymentThresholdSetting];

/* 販路の取扱売上ファイルのタイプ */
export const ReadUnlimitedTypes = {
  NORMAL: {
    id: 0,
    description: '通常販売',
  },
  READ_UNLIMITED: {
    id: 1,
    description: '読み放題',
  },
  MIX: {
    id: 2,
    description: '混在',
  },
};

/* 売上データの読み放題フラグ */
export const ReadUnlimitedFlags = {
  NORMAL: {
    id: 'NORMAL',
    description: '通常販売',
  },
  READ_UNLIMITED: {
    id: 'READ_UNLIMITED',
    description: '読み放題',
  },
};
export type ReadUnlimitedFlags =
  (typeof ReadUnlimitedFlags)[keyof typeof ReadUnlimitedFlags];

/* 売上データの読み放題選択用 */
export const ReadUnlimitedSelections = {
  ALL: {
    id: '',
    description: '全て',
  },
  NORMAL: {
    id: 'NORMAL',
    description: '通常販売',
  },
  READ_UNLIMITED: {
    id: 'READ_UNLIMITED',
    description: '読み放題',
  },
};
export type ReadUnlimitedSelections =
  (typeof ReadUnlimitedSelections)[keyof typeof ReadUnlimitedSelections];

/* 閾値未満支払通知を行うかどうか */
export const NotifiesPaymentWhenUnderThreshold = {
  SHOULD_NOTIFY: { id: 'SHOULD_NOTIFY', text: '通知する' },
  SHOULD_NOT_NOTIFY: { id: 'SHOULD_NOT_NOTIFY', text: '通知しない' },
};
export type NotifiesPaymentWhenUnderThreshold =
  (typeof NotifiesPaymentWhenUnderThreshold)[keyof typeof NotifiesPaymentWhenUnderThreshold];

export const RecoupmentUnit = {
  PRODUCT_GROUP: { id: 'PRODUCT_GROUP', text: '作品' },
  TITLE: { id: 'TITLE', text: 'タイトル' },
  PRODUCT: { id: 'PRODUCT', text: '商品' },
};
export type RecoupmentUnit =
  (typeof RecoupmentUnit)[keyof typeof RecoupmentUnit];

export const PrepaymentState = {
  AGGREGATION_DONE: { id: 'AGGREGATION_DONE', text: '集計済み' },
  PENDING: { id: 'PENDING', text: '未集計' },
};
export type PrepaymentState =
  (typeof PrepaymentState)[keyof typeof PrepaymentState];
