import { Component, OnInit, Output, EventEmitter, HostListener, OnDestroy } from '@angular/core';
import { Tenant, TenantsService } from 'src/app/services/base/tenants.service';
import { AuthService, AuthenticationState } from '../../auth.service';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'support-user-form',
  templateUrl: './support-user-form.html',
  styleUrls: ['./form.scss']
})
export class SupportUserForm implements OnInit, OnDestroy {

  @Output() next = new EventEmitter<string>();

  processing: boolean;
  tenantList: Tenant[];
  tenant = new UntypedFormControl("");

  constructor(
    private tenantSv: TenantsService,
    private authSv: AuthService,
  ) { }

  ngOnInit(): void {
    this.tenantSv.all().subscribe(resp => {
      if (resp.success) {
        this.tenantList = resp.data;
      }
    })
  }

  ngOnDestroy(): void {
    this.onUnload(null);
  }

  @HostListener('window:unload', ['$event'])
  onUnload(_): void {
    if (!this.authSv.user.tenant_id_support || !this.authSv.user.admin_flg) {
      this.authSv.logoutForcibly();
    }
  }

  onSubmit(): void {
    var tenantId = this.tenant.value;
    if (!tenantId) {
      return;
    }

    if (this.tenant.invalid) {
      return;
    }

    this.processing = true;
    // 再度トークン作成処理リクエストを投げる
    this.authSv.refreshContextAsSupport(tenantId)
      .subscribe(
        res => {
          if (res) {
            if (!this.authSv.user.admin_flg) {
              this.tenant.setErrors({ "failed": "サポートユーザーに管理者権限(admin_flg)がありません" });
              return;
            }
            this.authSv.onStateChanged.next(AuthenticationState.Authenticated);
            this.next.emit(null);
          } else {
            this.tenant.setErrors({ "failed": "ログインに失敗しました" });
          }
        },
        null,
        () => {
          this.processing = false;
        }
      )
  }

}
