import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { map } from 'rxjs/operators';
import { PubnaviModule, PubnaviModules } from './pubnavi-module.constants';

export interface MenuGroup {
  pubnaviModule: PubnaviModule;
  icon: string;
  menus: Menu[];
}

export interface Menu {
  order: number;
  auth_id: string;
  prefix?: string;
  title: string;
  link: string;
}

const convertPubnaviModToMenus = (parentMod: PubnaviModule): Menu[] => {
  const result: Menu[] = [];

  let pubMods = parentMod.childModules;
  Object.entries(pubMods).forEach(val => {
    let pubMod: PubnaviModule = val[1];
    let menu: Menu = {
      order: pubMod.order,
      auth_id: pubMod.authId,
      prefix: "",
      title: pubMod.title,
      link: "/" + parentMod.path + "/" + pubMod.path
    }
    result.push(menu)
  });

  return result;
}

const ALL_MENUS: MenuGroup[] = [
  {
    pubnaviModule: PubnaviModules.EbookSales,
    icon: 'devices',
    menus: convertPubnaviModToMenus(PubnaviModules.EbookSales)
  },
  {
    pubnaviModule: PubnaviModules.Payment,
    icon: 'payment',
    menus: convertPubnaviModToMenus(PubnaviModules.Payment)
  },
  {
    pubnaviModule: PubnaviModules.Setting,
    icon: 'settings',
    menus: convertPubnaviModToMenus(PubnaviModules.Setting)
  },
  {
    pubnaviModule: PubnaviModules.UserLog,
    icon: 'desktop_windows',
    menus: convertPubnaviModToMenus(PubnaviModules.UserLog)
  },
];

@Injectable()
export class MenusService {

  constructor(
    private auth: AuthService
  ) { }

  getAvailables(): Observable<MenuGroup[]> {
    return this.auth.getAuthorities().pipe(
      map(
        resp => {
          if(!resp.data || !resp.data.auth_id_map) {
            return [];
          }
          const modifiedMenu: MenuGroup[] = [];
          Object.keys(resp.data.auth_id_map).forEach((authId) => {
            this.modifyMenuItems(modifiedMenu, authId, resp.data.auth_id_map[authId]);
          })
          // ソート
          modifiedMenu.sort((a,b) => a.pubnaviModule.order - b.pubnaviModule.order);
          modifiedMenu.forEach(m => m.menus.sort((a,b) => a.order - b.order));

          return modifiedMenu;
        }
      )
    );
  }

  private modifyMenuItems(modifiedMenu: MenuGroup[], authId: string, isShow: boolean){
    ALL_MENUS.forEach((menuGrp) => {
      menuGrp.menus.forEach((menu) => {
        if(menu.auth_id === authId && isShow){
          this.setMenu(modifiedMenu, menuGrp, menu)
        }
      });
    });
  }

  private setMenu(modifiedMenu: MenuGroup[] ,menuGrp: MenuGroup, menu: Menu){
    // menuGrpが形成済みかをチェック
    var targetGrp: MenuGroup = null;
    modifiedMenu.forEach(modGrp => {
      if(modGrp.pubnaviModule.moduleKey === menuGrp.pubnaviModule.moduleKey){
        targetGrp = modGrp;
      }
    });

    if(targetGrp) {
      // あるなら、そこにmenuを足すだけ
      targetGrp.menus.push(menu);
    } else {
      // 無いなら、menuGrpを足してmenu追加
      const newGrp: MenuGroup = {
        pubnaviModule: menuGrp.pubnaviModule,
        icon: menuGrp.icon,
        menus: [menu],
      }
      modifiedMenu.push(newGrp);
    }
  }
}
