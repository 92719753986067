import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  ListFilter,
  NOTIFICATION_TYPES,
  PartialList,
  Response,
  StdModel,
} from './../models';

export interface YearlyPaymentRecordSchedule extends StdModel {
  yyyy?: string;
  status?: string;
  tax_excluded_amount?: number;
  consumption_tax?: number;
  tax_included_amount?: number;
  withholding_tax?: number;
  fixed_payment_amount?: number;
  invoked_at?: Date;
  invoked_by?: string;
}

export interface PaymentRecord extends StdModel {
  id: string;
  yyyy?: string;
  payee_code?: string;
  payee_name?: string;
  payee_email?: string;
  payee_payment_record_notification_type?: number;
  tax_excluded_amount?: number;
  consumption_tax?: number;
  tax_included_amount?: number;
  withholding_tax?: number;
  fixed_payment_amount?: number;
  notification_emailed_at?: Date;
  notification_emailed_by?: string;
  notification_downloaded_at?: Date;
  notification_downloaded_by?: string;
  name_of_notification_downloaded_by?: string;
  name_of_notification_emailed_by?: string;

  payee_payment_record_notification_type_text?: string;
}

export interface PaymentRecordsBulkNotifyProcArgs {
  yyyy: string;
  send_option: string;
}

export interface PaymentRecordsNotifySingleArgs {
  id: string;
  yyyy: string;
}

export interface EnqueueYearlyProcessResults {
  worker_id?: string;
  status?: string;
}

export interface PaymentRecordFilter extends ListFilter {
  yyyy?: string;
  payee_payment_record_notification_type?: number;
}

@Injectable()
export class PaymentRecordsService {
  constructor(private http: HttpClient) {}

  private formatPaymentResponse(resp: PaymentRecord[]) {
    resp.forEach((e) => {
      e.notification_emailed_at &&
        (e.notification_emailed_at = new Date(e.notification_emailed_at));
      e.notification_downloaded_at &&
        (e.notification_downloaded_at = new Date(e.notification_downloaded_at));
      e.payee_payment_record_notification_type_text =
        NOTIFICATION_TYPES[e.payee_payment_record_notification_type];
    });
  }

  getYearlyPaymentSchedule(
    yyyy: string
  ): Observable<Response<YearlyPaymentRecordSchedule>> {
    return this.http.get<Response<YearlyPaymentRecordSchedule>>(
      `/authorized/payment-records/schedules/${yyyy}`
    );
  }

  listPaymentRecordsByFilter(
    d: PaymentRecordFilter
  ): Observable<Response<PartialList<PaymentRecord>>> {
    let params = new HttpParams()
      .append('yyyy', d.yyyy)
      .append('limit', String(d.limit))
      .append('offset', String(d.offset));
    isFinite(d.payee_payment_record_notification_type) &&
      (params = params.append(
        'payee_payment_record_notification_type',
        String(d.payee_payment_record_notification_type)
      ));
    return this.http
      .get<Response<PartialList<PaymentRecord>>>(
        `/authorized/payment-records/resources`,
        { params }
      )
      .pipe(tap((res) => this.formatPaymentResponse(res.data.list)));
  }

  postAggregate(
    yyyy: string
  ): Observable<Response<EnqueueYearlyProcessResults>> {
    return this.http.post<Response<EnqueueYearlyProcessResults>>(
      `/authorized/payment-records/aggregate`,
      { yyyy }
    );
  }

  postAggregateRevert(
    yyyy: string
  ): Observable<Response<EnqueueYearlyProcessResults>> {
    return this.http.post<Response<EnqueueYearlyProcessResults>>(
      `/authorized/payment-records/aggregate/revert`,
      { yyyy }
    );
  }

  postConfirm(yyyy: string): Observable<Response<EnqueueYearlyProcessResults>> {
    return this.http.post<Response<EnqueueYearlyProcessResults>>(
      `/authorized/payment-records/confirm`,
      { yyyy }
    );
  }

  bulkGetNotificationDownloadURL(
    yyyy: string,
    notification_type: string
  ): Observable<Response<any>> {
    return this.http.get<Response<any>>(
      `/authorized/payment-records/notify/bulk-download/${yyyy}`,
      { params: { notification_type } }
    );
  }

  postConfirmRevert(
    yyyy: string
  ): Observable<Response<EnqueueYearlyProcessResults>> {
    return this.http.post<Response<EnqueueYearlyProcessResults>>(
      `/authorized/payment-records/confirm/revert`,
      { yyyy }
    );
  }

  postNotify(
    args: PaymentRecordsBulkNotifyProcArgs
  ): Observable<Response<EnqueueYearlyProcessResults>> {
    return this.http.post<Response<EnqueueYearlyProcessResults>>(
      `/authorized/payment-records/notify`,
      args
    );
  }

  postNotifyDone(
    yyyy: string
  ): Observable<Response<EnqueueYearlyProcessResults>> {
    return this.http.post<Response<EnqueueYearlyProcessResults>>(
      `/authorized/payment-records/notify/done`,
      { yyyy }
    );
  }

  postNotifyRevert(
    yyyy: string
  ): Observable<Response<EnqueueYearlyProcessResults>> {
    return this.http.post<Response<EnqueueYearlyProcessResults>>(
      `/authorized/payment-records/notify/revert`,
      { yyyy }
    );
  }

  postSingleNotify(
    args: PaymentRecordsNotifySingleArgs
  ): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `/authorized/payment-records/notify-single`,
      args
    );
  }

  getNotifyDownloadURL(
    yyyy: string,
    payee_code: string
  ): Observable<Response<string>> {
    return this.http
      .get<Response<any>>(
        `/authorized/payment-records/notify/download-url/${yyyy}/${payee_code}`
      )
      .pipe(
        tap((res) => {
          res.data = res.data.url;
        })
      );
  }

  postDone(yyyy: string): Observable<Response<EnqueueYearlyProcessResults>> {
    return this.http.post<Response<EnqueueYearlyProcessResults>>(
      `/authorized/payment-records/execute/done`,
      { yyyy }
    );
  }

  postDoneRevert(
    yyyy: string
  ): Observable<Response<EnqueueYearlyProcessResults>> {
    return this.http.post<Response<EnqueueYearlyProcessResults>>(
      `/authorized/payment-records/execute/revert`,
      { yyyy }
    );
  }

  getRecordsCsvDownloadURL(yyyy: string): Observable<Response<string>> {
    return this.http
      .get<Response<any>>(
        `/authorized/payment-records/execute/download-url/${yyyy}`
      )
      .pipe(
        tap((res) => {
          res.data = res.data.url;
        })
      );
  }

  getPayeesCsvDownloadURL(yyyy: string): Observable<Response<string>> {
    return this.http
      .get<Response<any>>(
        `/authorized/payment-records/execute/payees-download-url/${yyyy}`
      )
      .pipe(
        tap((res) => {
          res.data = res.data.url;
        })
      );
  }
}
