// DB負荷やLambdaレスポンス6MBの制限の考慮の上、利用を検討してください。
export const DOWNLOAD_THRESHOLD = 100000;
export const DOWNLOAD_INFINITE = 999999;

// 売上確認・変更画面から10万件の一括ファイルDL時に、大量のSQLがDBに発行されてしまい、DBの負荷（CPU使用率）が上昇し、API Gatewayのタイムアウトエラーが発生した。
// 2万件であればエラーは発生せず、DBへの負荷（CPU使用率）も単発であれば20%程に収まるため、一括DLの実装の変更（根本的な対応）までは、2万件で運用する。
export const DOWNLOAD_THRESHOLD_EBOOK_SALES = 20000;
export const SPLIT_REQUEST_LIMIT_EBOOK_SALES = 1000;

// サーバー化の前の暫定対応。(約4000件のDLで、Lambdaレスポンス6MBの制限に引っかかる)
export const DOWNLOAD_THRESHOLD_PAYEE = 20000;
export const SPLIT_REQUEST_LIMIT_PAYEE = 3000;

// サーバー化の前の暫定対応。(約25000件のDLで、Lambdaレスポンス6MBの制限に引っかかる)
export const DOWNLOAD_THRESHOLD_PRODUCT_CODE_MAPPING = 10000;

/** APIレスポンス用エラーハンドリング */
export const BusinessLogicError = {
  // 一意制約違反を表すエラーです。
  DuplicateEntry: 'duplicate entry',
  // リレーションデータが存在するため削除が出来ないエラーです。
  Undeletable: 'undeletable',
  // リクエストのパラメータが不正である場合のエラーです。
  BadRequestParameter: 'bad request parameter',
  // 検証結果が不正の場合のエラーです。
  IncorrectValidationResults: 'incorrect validation results',
  // ダウンロードファイルの作成が完了していない場合のエラーです。
  DownloadFileCreationIncomplete: 'download file creation incomplete',
  // DB上にレコードが存在しない場合のエラーです。
  RecordShouldExistButNotFound: 'record should exist but not found',
} as const;
export type BusinessLogicError = typeof BusinessLogicError[keyof typeof BusinessLogicError];

// PUBNAVIで扱う日付の最小値(Mon Jan 01 1900 00:00:00)
export const SYSTEM_MIN_DATE = new Date(1900, 0, 1)
