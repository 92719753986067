import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Response, StdModel, FailedResponseData } from './../models';

export const InputType = {
  IMPORT: { id: 'IMPORT', text: '取込' },
  INPUT: { id: 'INPUT', text: '手入力' },
} as const;
export type InputType = typeof InputType[keyof typeof InputType];

export interface EbookMonthlySalesSchedule extends StdModel {
  yyyymm?: string;
  tasks_of_this_month?: string[],
}

export interface EbookMonthlySalesSchedule extends StdModel {
  sales_route_code?: string;
  sales_route_name?: string;
  sales_period_text?: string;
  yyyymm?: string;
  pre_fix_type?: string;
  read_unlimited_flg?: boolean;
  status?: string;
  imported_row_cnt?: number;
  download_cnt_total?: number;
  pv_cnt_total?: number;
  tax_excluded_sales_total?: number;
  tax_included_sales_total?: number;
  tax_excluded_deposit_total?: number;
  tax_included_deposit_total?: number;
  int_tax_excluded_sales_total?: number;
  int_tax_included_sales_total?: number;
  int_tax_excluded_deposit_total?: number;
  int_tax_included_deposit_total?: number;
  planned_date?: Date;
  proc_start_at?: Date;
  proc_end_at?: Date;
  input_type?: string;
  file_path?: string;
  user_name?: string;
}

export interface EbookMonthlyRoyaltySchedule extends StdModel {
  pre_fix_type?: string;
  status?: string;
  planned_date?: Date;
  sales_closed_by?: string;
  total_start_at?: Date;
  total_end_at?: Date;
  tax_excluded_sales_total?: number;
  tax_included_sales_total?: number;
  tax_excluded_deposit_total?: number;
  tax_included_deposit_total?: number;
  int_tax_excluded_sales_total?: number;
  int_tax_included_sales_total?: number;
  int_tax_excluded_deposit_total?: number;
  int_tax_included_deposit_total?: number;
  total_download_cnt?: number;
  calc_start_at?: Date;
  calc_end_at?: Date;
  tax_excluded_royalty_total?: number;
  tax_included_royalty_total?: number;
  int_tax_excluded_royalty_total?: number;
  int_tax_included_royalty_total?: number;
  target_sales_schedule_yyyymm?: string[];
  fixed_flg?: number;
  fixed_at?: Date;
  fixed_by?: string;
  payment_schedule_id?: string;
  payment_schedule_yyyymm?: string;
}

export interface EbookRoyaltyCalcCanExecResponse {
  can_exec: boolean;
  reasons?: string[];
  worker_id?: string;
}

export const EBOOK_SALES_IMPORT_STATUS = {
  PENDING: "PENDING",
  PROCESSING: "PROCESSING",
  //AUTOPROC: "AUTOPROC" 売上自動連携で使用されるステータスだが、現状利用されていないのでコメントアウトしています
  DONE: "DONE",
  ERROR: "ERROR",
  SYS_ERROR: "SYS_ERROR",
  LOCKED: "LOCKED"
}

@Injectable()
export class EbookSchedulesService {

  constructor(
    private http: HttpClient,
  ) { }

  private formatRoyaltyScheduleResponse(resp: EbookMonthlyRoyaltySchedule[]) {
    resp.forEach(e => {
      e.planned_date = new Date(e.planned_date);
    });
  }

  patchSalesSchdule(id: string, status: string): Observable<Response<any>> {
    return this.http.patch<Response<any>>(`/authorized/ebook-schedules/sales/${id}`, {
      status: status
    });
  }

  listMonthlySalesSchedules(yyyymm: string): Observable<Response<EbookMonthlySalesSchedule[]>> {
    return this.http.get<Response<EbookMonthlySalesSchedule[]>>(`/authorized/ebook-schedules/sales`, { params: { yyyymm } });
  }

  getMonthlySalesSchedule(id: string): Observable<Response<EbookMonthlySalesSchedule>> {
    return this.http.get<Response<EbookMonthlySalesSchedule>>(`/authorized/ebook-schedules/sales/${id}`);
  }

  listMonthlyRoyaltySchedules(year: string): Observable<Response<EbookMonthlyRoyaltySchedule[]>> {
    return this.http.get<Response<EbookMonthlyRoyaltySchedule[]>>(`/authorized/ebook-schedules/royalty`, { params: { year } }).pipe(
      tap(res => this.formatRoyaltyScheduleResponse(res.data))
    );
  }

  getCanExecCalc(target_sales_schedule_yyyymm: string[], pre_fix_type: string): Observable<Response<EbookRoyaltyCalcCanExecResponse>> {
    return this.http.get<Response<EbookRoyaltyCalcCanExecResponse>>(`/authorized/ebook-schedules/royalty/can-exec-calc`, {
      params: {
        target_sales_schedule_yyyymm: target_sales_schedule_yyyymm.join(','),
        pre_fix_type: pre_fix_type,
      }
    });
  }

  getMonthlyRoyaltySchedule(id: string): Observable<Response<EbookMonthlyRoyaltySchedule>> {
    return this.http.get<Response<EbookMonthlyRoyaltySchedule>>(`/authorized/ebook-schedules/royalty/single?id=${id}`).pipe(
      tap(res => this.formatRoyaltyScheduleResponse([res.data]))
    );
  }

  putSalesTotalProcess(d: EbookMonthlyRoyaltySchedule): Observable<Response<any>> {
    return this.http.put<Response<any>>(`/authorized/ebook-schedules/sales-total/process`, {
      royalty_schedule_id: d.id,
      pre_fix_type: d.pre_fix_type,
      target_sales_schedule_yyyymm: d.target_sales_schedule_yyyymm,
    });
  }

  putSalesTotalProcessCancel(d: EbookMonthlyRoyaltySchedule): Observable<Response<any>> {
    return this.http.put<Response<any>>(`/authorized/ebook-schedules/sales-total/cancel`, {
      royalty_schedule_id: d.id,
      pre_fix_type: d.pre_fix_type,
      target_sales_schedule_yyyymm: d.target_sales_schedule_yyyymm,
    });
  }

  putRoyaltyConfirmProcess(royalty_schedule_id: string): Observable<Response<any>> {
    return this.http.put<Response<any>>(`/authorized/ebook-schedules/royalty/confirm/${royalty_schedule_id}`, {});
  }

  putRoyaltyConfirmProcessCancel(royalty_schedule_id: string): Observable<Response<any>> {
    return this.http.put<Response<any>>(`/authorized/ebook-schedules/royalty/cancel-confirm/${royalty_schedule_id}`, {});
  }

  CheckDeletableSalesSchedules(routecode: string, fromYyyymm: string): Observable<Response<FailedResponseData>> {
    return this.http.get<Response<FailedResponseData>>(`/authorized/ebook-schedules/deletable/sales?route_code=${routecode}&from_yyyymm=${fromYyyymm}`);
  }

  DeleteDeletableSalesSchedules(routecode: string, fromYyyymm: string): Observable<Response<any>> {
    return this.http.delete<Response<any>>(`/authorized/ebook-schedules/sales?route_code=${routecode}&from_yyyymm=${fromYyyymm}`);
  }
}
