import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  EbookMonthlyRoyaltySchedule,
  EbookMonthlySalesSchedule,
} from '../ebook/ebook-schedules.service';
import { YearlyPaymentRecordSchedule } from '../payment/payment-records.service';
import { MonthlyPaymentSchedule } from '../payment/payments.service';
import { FailedResponseData, Response, SimpleBusinessError } from './../models';

export interface MonthlyScheduleDefinition {
  id?: string;
  name?: string;
  payment_monthly_settings: PaymentMonthlySetting[];
  royalty_calc_monthly_settings: RoyaltyCalcMonthlySetting[];
  royalty_calc_dd?: number;
  shift_by_holiday_type?: string;
  first_payment_start_yyyymm?: string;
  payment_processed?: boolean;
}

export interface PaymentMonthlySetting {
  pay_date: number;
  with_royalty: boolean;
}

export function cloneSetting(
  origin: PaymentMonthlySetting
): PaymentMonthlySetting {
  return {
    pay_date: origin.pay_date,
    with_royalty: origin.with_royalty,
  };
}

export interface RoyaltyCalcMonthlySetting {
  sales_calc_sets: SalesCalcSet[];
  payment?: number;
}

export interface SalesCalcSet {
  calc?: number;
  target_sales: number[];
}

export interface ScheduleRegisterRequest {
  definition_id: string;
  payment_yyyy: string;
  modified_definition: MonthlyScheduleDefinition;
}

export interface ScheduleRegisterYyyymmResponse {
  yyyymm_list: ScheduleGroupYyyymm[];
  payment_yyyy: string;
}

export interface ScheduleGroupYyyymm {
  royalty_calc_yyyymm_sets: RoyaltyCalcYyyymmSet[];
  payment_yyyymm: string;
}

export interface RoyaltyCalcYyyymmSet {
  royalty_yyyymm: string;
  sales_yyyymmm_List: string[];
}

export interface ScheduleRegisterGroupResponse {
  schedule_groups: ScheduleGroupResult[];
  yearly_payment_schedule: YearlyPaymentRecordSchedule;
  ng_reasons: string[];
}

export interface ScheduleGroupResult {
  sales_schedules: EbookMonthlySalesSchedule[];
  royalty_schedules: EbookMonthlyRoyaltySchedule[];
  payment_schedule: MonthlyPaymentSchedule;
}

// 画面表示用構造
export interface ScheduleDefNode {
  name?: string;
  children?: ScheduleDefNode[];
  idx: number;
}

export interface ScheduleDefFlatTreeNode {
  expandable: boolean;
  name: string;
  level: number;
  idx: number;
}

export interface RemakeableSalesInfo {
  sales_yyyymms: string[];
  message: string;
}

export interface DeleteableSalesInfo {
  target_yyyymms: string[];
  start_yyyymm: string;
  last_yyyymm: string;
  message: string;
}

export function NodeTransformer(
  node: ScheduleDefNode,
  level: number
): ScheduleDefFlatTreeNode {
  return {
    expandable: !!node.children && node.children.length > 0,
    name: node.name,
    level: level,
    idx: node.idx,
  };
}

export function HasChild(_: number, node: ScheduleDefFlatTreeNode): boolean {
  return !!node.expandable;
}

export const pmsErrorType = {
  tooLongError: 'tooLongError',
  lengthZeroError: 'lengthZeroError',
  royaltyOver: 'royaltyOver',
  noRoyalty: 'noRoyalty',
};

export function PaymentMonthlySettingsValidator(
  control: AbstractControl
): { [key: string]: any } | null {
  if (control.value) {
    const err: { [key: string]: any } = {};
    if (control.value.length > 1) {
      // TODO 支払スケジュールのyyyymmがuniqueである前提で作られたロジックが全廃できたら、削除
      err[pmsErrorType.tooLongError] = { value: control.value };
      return err;
    }
    if (control.value.length == 0) {
      err[pmsErrorType.lengthZeroError] = { value: control.value };
      return err;
    }
    const settings = control.value as PaymentMonthlySetting[];
    var royaltyCount = 0;
    settings.forEach((val) => {
      if (val.with_royalty) {
        royaltyCount++;
      }
    });
    if (royaltyCount > 1) {
      err[pmsErrorType.royaltyOver] = { value: control.value };
      return err;
    } else if (royaltyCount < 1) {
      err[pmsErrorType.noRoyalty] = { value: control.value };
      return err;
    }
  }
  return null;
}

@Injectable()
export class ScheduleDefinitionService {
  private route: string = '/authorized/schedule-register';

  constructor(private http: HttpClient) {}

  get(): Observable<Response<MonthlyScheduleDefinition>> {
    return this.http.get<Response<MonthlyScheduleDefinition>>(
      this.route + `/definition`
    );
  }

  post(
    d: MonthlyScheduleDefinition
  ): Observable<Response<SimpleBusinessError>> {
    return this.http.post<Response<SimpleBusinessError>>(
      this.route + `/definition`,
      d
    );
  }

  DeleteSchedules(
    yyyy: string
  ): Observable<Response<{ ng_reason_map: Map<string, string[]> }>> {
    return this.http
      .delete<Response<{ ng_reason_map: Map<string, string[]> }>>(
        this.route + `/schedule-group/` + yyyy
      )
      .pipe(tap((res) => this.formatNgReasonsToMap(res)));
  }

  PreCheck(
    id: string,
    yyyy: string,
    def?: MonthlyScheduleDefinition
  ): Observable<Response<ScheduleRegisterYyyymmResponse>> {
    const param: ScheduleRegisterRequest = {
      definition_id: id,
      payment_yyyy: yyyy,
      modified_definition: def,
    };
    return this.http.put<Response<ScheduleRegisterYyyymmResponse>>(
      this.route + `/schedule-group/pre-check`,
      param
    );
  }

  CheckResult(
    id: string,
    paymentYyyy: string,
    def?: MonthlyScheduleDefinition
  ): Observable<Response<ScheduleRegisterGroupResponse>> {
    const param: ScheduleRegisterRequest = {
      definition_id: id,
      payment_yyyy: paymentYyyy,
      modified_definition: def,
    };
    return this.http
      .put<Response<ScheduleRegisterGroupResponse>>(
        this.route + `/schedule-group/check-result`,
        param
      )
      .pipe(tap((res) => this.formatScheduleGroupResponse(res.data)));
  }

  MakeSchedules(
    id: string,
    paymentYyyy: string
  ): Observable<Response<ScheduleRegisterGroupResponse | FailedResponseData>> {
    const param: ScheduleRegisterRequest = {
      definition_id: id,
      payment_yyyy: paymentYyyy,
      modified_definition: null,
    };
    return this.http.post<
      Response<ScheduleRegisterGroupResponse | FailedResponseData>
    >(this.route + `/schedule-group/make`, param);
  }

  ShouldRemakeYyyymms(): Observable<Response<RemakeableSalesInfo>> {
    return this.http.get<Response<RemakeableSalesInfo>>(
      this.route + `/sales-schedules/should-remake-yyyymms`
    );
  }

  RemakeSalesSchedules(
    routecode: string,
    fromYyyymm: string
  ): Observable<Response<FailedResponseData>> {
    return this.http.post<Response<FailedResponseData>>(
      this.route + `/sales-schedules/remake`,
      {
        route_code: routecode,
        from_yyyymm: fromYyyymm,
      }
    );
  }

  ShouldDeleteSalesRoute(
    salesRouteCode: string
  ): Observable<Response<DeleteableSalesInfo>> {
    return this.http.post<Response<DeleteableSalesInfo>>(
      this.route + `/sales-schedules/should-delete-sales-route-code`,
      {
        sales_route_code: salesRouteCode,
      }
    );
  }

  DeleteSalesRoute(
    salesRouteCode: string
  ): Observable<Response<DeleteableSalesInfo>> {
    return this.http.post<Response<DeleteableSalesInfo>>(
      this.route + `/sales-schedules/delete-sales-route-code`,
      {
        sales_route_code: salesRouteCode,
      }
    );
  }

  newMonthlyScheduleDefinition(): MonthlyScheduleDefinition {
    return {
      id: '',
      name: '※未設定',
      use_preliminary: false,
      exist_read_unlimited: false,
      royalty_calc_dd: 0,
      shift_by_holiday_type: ShiftByHolidayType.NONE.value,
      first_payment_start_yyyymm: '',

      payment_monthly_settings: [],
      royalty_calc_monthly_settings: [],
    } as MonthlyScheduleDefinition;
  }

  formatScheduleGroupResponse(data: ScheduleRegisterGroupResponse) {
    data.schedule_groups?.forEach((grp) => {
      grp.royalty_schedules.forEach((roy) => {
        roy.planned_date = new Date(roy.planned_date);
      });
      grp.sales_schedules.forEach((sales) => {
        sales.planned_date = new Date(sales.planned_date);
      });
    });
  }

  formatNgReasonsToMap(
    res: Response<{ ng_reason_map: Map<string, string[]> }>
  ) {
    if (res.data) {
      res.data.ng_reason_map = new Map(Object.entries(res.data.ng_reason_map));
    }
  }
}

// 画面用固定値
export const ShiftByHolidayType = {
  FORWARD: { text: '前倒し', value: 'FORWARD' },
  NONE: { text: '変更なし', value: 'NONE' },
  BACKWARD: { text: '後ろ倒し', value: 'BACKWARD' },
};
export type ShiftByHolidayType =
  (typeof ShiftByHolidayType)[keyof typeof ShiftByHolidayType];

export const RoyaltyCalcTiming = {
  Sales: { text: '売上ごと', value: 'sales' },
  AfterSales: { text: '売上の翌月', value: 'after-sales' },
  Payment: { text: '支払ごと', value: 'payment' },
  BeforePayment: { text: '支払の前月', value: 'before-payment' },
};

export type RoyaltyCalcTiming =
  (typeof RoyaltyCalcTiming)[keyof typeof RoyaltyCalcTiming];
