import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map, mergeMap } from "rxjs/operators";
import { TenantsService } from 'src/app/services/base/tenants.service';
import { AuthService, UserContext } from './../../auth/auth.service';
import { RouteData } from './../../services/models';

@Component({
  selector: 'envelope',
  templateUrl: './envelope.component.html',
  styleUrls: ['./envelope.component.scss']
})
export class EnvelopeComponent implements OnInit, OnDestroy {

  @ViewChild('drawer') drawer: MatDrawer;

  user: UserContext;
  tenantName: Observable<string>;
  routeData: RouteData;

  isSmallScreen: boolean;

  private subsc = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private breakpointObserver: BreakpointObserver,
    private auth: AuthService,
    private tenants: TenantsService,
  ) { }

  ngOnInit() {
    this.user = this.auth.user;
    this.tenantName = this.tenants.get().pipe(map(r => r.data.name));

    // Set title.
    this.handleRouteData(this.toFirstChildRoute(this.route).snapshot.data);

    // Reset scroller when page transition.
    this.subsc.add(this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
      , map(() => this.route)
      , map(route => {
        let result = route;
        while (result.firstChild) result = result.firstChild;
        return result;
      })
      , filter(route => route.outlet === 'primary')
      , mergeMap(route => route.data)
    ).subscribe(data => {
      this.handleRouteData(data);
      this.resetScrollTop();
      this.drawer.close();
    }));

    this.subsc.add(this.breakpointObserver.observe([
      '(max-width:1087px)'
    ]).subscribe(result => {
      this.isSmallScreen = result.matches;
    }));
  }

  ngOnDestroy() {
    this.subsc.unsubscribe();
  }

  private toFirstChildRoute(route) {
    let result = route;
    while (result.firstChild) result = result.firstChild;
    return result;
  }

  private handleRouteData(data) {
    this.routeData = data as RouteData;
    this.title.setTitle(`${this.routeData.title} | PUBNAVI` || 'PUBNAVI');
  }

  private resetScrollTop() {
    let scrollingEl = document.scrollingElement || document.documentElement;
    scrollingEl && (scrollingEl.scrollTop = 0);
  }

  onLogoutClick() {
    this.auth.logout().subscribe({
      next: _ => this.router.navigate(['/'])
    });
  }
}
