import { Injectable } from '@angular/core';
import { SharedEbookSalesImportSetting, FileFormat } from './shared-ebook-sales-import-settings.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Response } from '../models';
import { PreFixType } from './ebook-sales.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EbookSalesImportSettingsService {

  constructor(
    private http: HttpClient,
  ) { }

  // 作成対象の販路と、設定の存否を組み合わせたAPI（設定のend_dateと組み合わせ
  checklist(): Observable<Response<ImportSettingChecklistResponse[]>> {
    return this.http.get<Response<ImportSettingChecklistResponse[]>>(`/authorized/sales-import-settings/checklist`);
  }

  listBy(salesRouteCode: string): Observable<Response<EbookSalesImportSetting[]>> {
    return this.http.get<Response<EbookSalesImportSetting[]>>(`/authorized/sales-import-settings/owned/${salesRouteCode}`)
      .pipe(
        tap(res => this.formatResponse(res.data))
      );
  };

  private formatResponse(resp: EbookSalesImportSetting[]) {
    resp.forEach(e => {
      e.file_format_text = FileFormat[e.file_format].text;
    })
  }

  // 個別に取得するAPI
  get(routeCode: string, endDate: string, preFixType: PreFixType): Observable<Response<EbookSalesImportSetting>> {
    return this.http.get<Response<EbookSalesImportSetting>>(`/authorized/sales-import-settings?route_code=${routeCode}&end_date=${endDate}&pre_fix_type=${preFixType.id}`)
      .pipe(
        tap(res => {
          if (res.data.file_format){
            res.data.file_format_text = FileFormat[res.data.file_format].text;
          }
        })
      );
  }
  // insert/update API
  post(data: EbookSalesImportSetting): Observable<Response<any>> {
    return this.http.post<Response<any>>(`/authorized/sales-import-settings`, data);
  }

  delete(r: ImportSettingChecklistResponse): Observable<Response<any>> {
    return this.http.delete<Response<any>>(`/authorized/sales-import-settings?route_code=${r.route_code}&end_date=${r.end_date}&pre_fix_type=${r.pre_fix_type}`)
  }
}

export interface ImportSettingChecklistResponse {
  route_code: string;
  end_date: string;
  pre_fix_type: string;

  route_name: string;
  has_setting: boolean;
}

export interface EbookSalesImportSetting extends SharedEbookSalesImportSetting { }
