export interface PubnaviModule {
  moduleKey: string;
  authId: string // authIdは、shared_authority.idと一致させること
  title: string;
  path: string;
  order: number;
  childModules?: { [moduleKey: string]: PubnaviModule };
};

const ebookSalesModules: { [moduleKey: string]: PubnaviModule } = {
  EbookSalesImport: { moduleKey: "EbookSalesImport", authId: "EbookSalesImport", title: "売上取込", path: "sales-import", order: 1 },
  EbookSalesManagement: { moduleKey: "EbookSalesManagement", authId: "EbookSalesManagement", title: "売上確認・変更", path: "sales-management", order: 2 },
  EbookSalesAnalysis: { moduleKey: "EbookSalesAnalysis", authId: "EbookSalesAnalysis", title: "売上分析", path: "sales-analysis", order: 3 },
  EbookRoyaltyCalculation: { moduleKey: "EbookRoyaltyCalculation", authId: "EbookRoyaltyCalculation", title: "印税計算", path: "royalty-calculation", order: 4 }
};

const paymentModules: { [moduleKey: string]: PubnaviModule } = {
  PaymentEditingCost: { moduleKey: "PaymentEditingCost", authId: "PaymentEditingCost", title: "編集原価", path: "editing-cost", order: 1 },
  Prepayment: { moduleKey: "Prepayment", authId: "Prepayment", title: "前払金", path: "prepayment", order: 2 },
  PaymentProcess: { moduleKey: "PaymentProcess", authId: "PaymentProcess", title: "支払処理", path: "pay-process", order: 3 },
  PaymentYearlyRecord: { moduleKey: "PaymentYearlyRecord", authId: "PaymentYearlyRecord", title: "支払調書", path: "yearly-record", order: 4 }
};

const settingModules: { [moduleKey: string]: PubnaviModule } = {
  BasicSetting: { moduleKey: "BasicSetting", authId: "BasicSetting", title: "基本設定", path: "basic", order: 1 },
  FormSetting: { moduleKey: "FormSetting", authId: "FormSetting", title: "帳票設定", path: "form-setting", order: 2 },
  ScheduleSetting: { moduleKey: "ScheduleSetting", authId: "ScheduleSetting", title: "スケジュール設定", path: "schedule", order: 3 },
  SalesRouteMaster: { moduleKey: "SalesRouteMaster", authId: "SalesRouteMaster", title: "販路マスタ", path: "sales-route", order: 4 },
  SalesImportSetting: { moduleKey: "SalesImportSetting", authId: "SalesImportSetting", title: "売上取込設定", path: "sales-import-setting", order: 5 },
  PayeeMaster: { moduleKey: "PayeeMaster", authId: "PayeeMaster", title: "支払先マスタ", path: "payee", order: 6 },
  EditingCostTypeMaster: { moduleKey: "EditingCostTypeMaster", authId: "EditingCostTypeMaster", title: "編集原価区分マスタ", path: "editing-cost-type", order: 7 },
  EbookProductMaster: { moduleKey: "EbookProductMaster", authId: "EbookProductMaster", title: "商品マスタ", path: "ebook-product", order: 8 },
  EbookProductCodeMappingMaster: { moduleKey: "EbookProductCodeMappingMaster", authId: "EbookProductCodeMappingMaster", title: "商品コード変換マスタ", path: "ebook-product-code-mapping", order: 9 },
  EbookProductTitleMaster: { moduleKey: "EbookProductTitleMaster", authId: "EbookProductTitleMaster", title: "商品タイトルマスタ", path: "ebook-product-title", order: 10 },
  EbookRoyaltyContractMaster: { moduleKey: "EbookRoyaltyContractMaster", authId: "EbookRoyaltyContractMaster", title: "印税契約マスタ", path: "ebook-royalty-contract", order: 11 },
  UserSetting: { moduleKey: "UserSetting", authId: "UserSetting", title: "ユーザー管理", path: "user", order: 12 },
  EmailSendingHistory: { moduleKey: "EmailSendingHistory", authId: "EmailSendingHistory", title: "メール送信履歴", path: "email-sending-history", order: 99 },
};

const userLogModules: { [moduleKey: string]: PubnaviModule } = {
  UserAccessLog: { moduleKey: "UserAccessLog", authId: "UserAccessLog", title: "ユーザーログ", path: "user-access-log", order: 1 },
}

// 最重要！：shared_authorities.moduleと設定固定値のペア。これを軸に、権限とメニューを全て制御している。
export const PubnaviModules: { [moduleKey: string]: PubnaviModule } = {
  EbookSales: { moduleKey: "EbookSales", authId: "", title: "電子書籍", path: "ebook", order: 1, childModules: ebookSalesModules },
  Payment: { moduleKey: "Payment", authId: "", title: "支払管理", path: "payment", order: 3, childModules: paymentModules },
  Setting: { moduleKey: "Setting", authId: "", title: "各種設定", path: "setting", order: 4, childModules: settingModules },
  UserLog: { moduleKey: "UserLog", authId: "", title: "モニタリング", path: "userlog", order: 5, childModules: userLogModules },
};
