<form autocomplete="off">

  <div class="entry">
    <p>今回利用するテナントを選んでください</p>
    <mat-form-field>
      <mat-select [formControl]="tenant">
        <mat-option value="">(未選択)</mat-option>
        <mat-option *ngFor="let t of tenantList" [value]="t.id">
          {{t.name +" ("+ t.id + ")"}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="tenant.hasError('failed')">{{tenant.getError("failed")}}</mat-error>
    </mat-form-field>
  </div>

  <div class="action">
    <span></span>
    <button mat-raised-button color="primary" [disabled]="processing" (click)="onSubmit()">決定</button>
  </div>
</form>