<div class="intro">メニュー</div>
<a class="home" matRipple routerLink="/portal">
  <mat-icon>home</mat-icon>
  <span>ホーム</span>
</a>
<mat-accordion displayMode="flat">
  <mat-expansion-panel *ngFor="let d of data;" [expanded]="d.expanded" (opened)="d.expanded = true;"
    (closed)="d.expanded = false;">
    <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px">
      <mat-panel-title>
        <div class="menu-group">
          <mat-icon>{{d.menuGroup.icon}}</mat-icon>
          <span>{{d.menuGroup.pubnaviModule.title}}</span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-nav-list>
      <a mat-list-item *ngFor="let m of d.menuGroup.menus" [routerLink]="m.link">
        <div class="menu-item">
          <span class="prefix" *ngIf="m.prefix">[{{m.prefix}}]</span>
          <span class="title">{{m.title}}</span>
        </div>
      </a>
    </mat-nav-list>
  </mat-expansion-panel>
</mat-accordion>