<form [formGroup]="form" autocomplete="off">

  <div class="entry">
    <mat-form-field appearance="outline">
      <mat-label>メールアドレス</mat-label>
      <input matInput placeholder="メールアドレス" formControlName="email" spellcheck="false" autofocus>
      <mat-error *ngIf="email.hasError('required')">メールアドレスを入力してください</mat-error>
      <mat-error *ngIf="email.hasError('email')">正しい形式のメールアドレスを入力してください</mat-error>
      <mat-error *ngIf="email.hasError('invalid')">メールアドレスとパスワードの組み合わせが違います</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>パスワード</mat-label>
      <input matInput placeholder="パスワード" [type]="passwordVisible ? 'text' : 'password'" formControlName="password"
        autocomplete="on">
      <a mat-icon-button matSuffix (click)="passwordVisible = !passwordVisible">
        <mat-icon>{{passwordVisible ? 'visibility' : 'visibility_off'}}</mat-icon>
      </a>
      <mat-error *ngIf="password.hasError('required')">パスワードを入力してください</mat-error>
      <mat-error *ngIf="password.hasError('invalid')">メールアドレスとパスワードの組み合わせが違います</mat-error>
    </mat-form-field>

    <p class="warning" *ngIf="result == 'Failed'">
      リクエストを受け付けることができませんでした<br>管理者へお問い合わせください
    </p>
  </div>

  <div class="action">
    <a mat-button color="primary" (click)="onPasswordForgotClick()">パスワードを忘れた場合</a>
    <button mat-raised-button color="primary" [disabled]="processing" (click)="onSubmit()">
      <span *ngIf="!processing">ログイン</span>
      <span *ngIf="processing">処理中...</span>
    </button>
  </div>

</form>