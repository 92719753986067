import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  FileUploadParams,
  ListFilter,
  PartialList,
  Response,
  StdModel,
} from './../models';

export const PreFixType = {
  FIXED: { id: 'FIXED', text: '確定値' },
  PRELIMINARY: { id: 'PRELIMINARY', text: '速報値' },
} as const;
export type PreFixType = typeof PreFixType[keyof typeof PreFixType];

export const UnitPriceTaxType = {
  TAX_INCLUDED: { id: 'TAX_INCLUDED', text: '税込み' },
  TAX_EXCLUDED: { id: 'TAX_EXCLUDED', text: '税抜き' },
} as const;
export type UnitPriceTaxType =
  typeof UnitPriceTaxType[keyof typeof UnitPriceTaxType];

export const InputType = {
  INPUT: { id: 'INPUT', text: '画面入力' },
  IMPORT: { id: 'IMPORT', text: 'ファイル取込' },
} as const;
export type InputType = typeof InputType[keyof typeof InputType];

export const YyyyMmTypes = {
  SALES_YYYY_MM: { id: 'SALES_YYYY_MM', text: '売上月' },
  STORE_SALES_YYYY_MM: { id: 'STORE_SALES_YYYY_MM', text: '書店販売月' },
} as const;
export type YyyyMmTypes = typeof YyyyMmTypes[keyof typeof YyyyMmTypes];

export const AggregatedTargets = {
  SALES_AMOUNT: { id: 'SALES_AMOUNT', text: '売上金額' },
  DEPOSIT_AMOUNT: { id: 'DEPOSIT_AMOUNT', text: '入金金額' },
  DOWNLOAD_CNT: { id: 'DOWNLOAD_CNT', text: 'DL数' },
  PV_CNT: { id: 'PV_CNT', text: 'PV数' },
} as const;
export type AggregatedTargets =
  typeof AggregatedTargets[keyof typeof AggregatedTargets];

export interface EbookSalesReportUploadRequest {
  schedule_id: string;
  file_name: string;
}

export interface EbookSalesReportDownloadRequest {
  schedule_id: string;
}

export interface EbookSalesReportImportRequest {
  schedule_id: string;
}

export interface EbookSales extends StdModel {
  input_type?: string;
  sales_route_code?: string;
  pre_fix_type?: string;
  read_unlimited_flg?: boolean;
  sales_yyyymm?: string;
  store_sales_yyyymm?: string;
  sales_tax_rate?: number;
  sales_route_product_code?: string;
  sales_route_product_name?: string;
  ebook_product_link_code?: string;
  ebook_product_code?: string;
  ebook_product_name?: string;
  tax_excluded_unit_price?: number;
  unit_price_tax_type?: string;
  deposit_rate?: number;
  download_cnt?: number;
  pv_cnt?: number;
  tax_excluded_sales_amount?: number;
  tax_included_sales_amount?: number;
  tax_excluded_deposit_amount?: number;
  tax_included_deposit_amount?: number;
  currency_code?: string;
  store_name?: string;
  store_company_name?: string;
  file_import_row?: number;
  sales_schedule_id?: string;
  sales_route_name?: string;
  name_of_created_by?: string;
  name_of_updated_by?: string;
}

export interface EbookSalesFilter extends ListFilter {
  pre_fix_type?: string;
  pre_fix_type_text?: string;
  sales_route_code?: string;
  sales_route_name?: string;
  sales_yyyymm_from?: string;
  sales_yyyymm_to?: string;
  ebook_product_code?: string;
  ebook_product_name?: string;
}

export interface EbookSalesImportIssue {
  id?: string;
  schedule_id?: string;
  file_import_row?: number;
  issue?: string;
}

export interface EbookSalesImportIssueFilter extends ListFilter {
  schedule_id?: string;
}

export interface EbookMonthlyProductSalesRanking {
  pre_fix_type?: string;
  sales_yyyymm?: string;
  ebook_product_code?: string;
  ebook_product_name?: string;
  tax_excluded_sales_total?: number;
  tax_included_sales_total?: number;
  total_download_cnt?: number;
}

export interface EbookMonthlySales {
  pre_fix_type?: string;
  sales_yyyymm?: string;
  sales_route_code?: string;
  sales_route_name?: string;
  tax_included_sales_total?: number;
  tax_excluded_sales_total?: number;
  int_tax_included_sales_total?: number;
  int_tax_excluded_sales_total?: number;
}

export interface MonthlyTransitionDownloadRequest {
  pre_fix_type?: string;
  aggregated_yyyy_mm?: string;
  aggregated_target?: string;
  yyyy_mm_from?: string;
  yyyy_mm_to?: string;
  product_codes?: string[];
  product_name?: string;
  product_group_name?: string;
  product_title_name?: string;
  author_name?: string;
  publishing_types?: string[];
  content_volumes: string[];
  sales_route_names?: string[];
  store_names?: string[];
  read_unlimited_type?: number;
  group_sales_route?: boolean;
  group_store_company?: boolean;
  group_store?: boolean;
  group_read_unlimited_type?: boolean;
  group_unit_price?: boolean;
  group_sales_yyyymm?: boolean;
  group_store_sales_yyyymm?: boolean;
}

@Injectable()
export class EbookSalesService {
  constructor(private http: HttpClient) {}

  getReportUploadURL(
    d: EbookSalesReportUploadRequest
  ): Observable<Response<FileUploadParams>> {
    return this.http.post<Response<FileUploadParams>>(
      `/authorized/ebook-sales/reports/upload-url`,
      d
    );
  }

  getReportDownloadURL(
    d: EbookSalesReportDownloadRequest
  ): Observable<Response<string>> {
    return this.http.post<Response<string>>(
      `/authorized/ebook-sales/reports/download-url`,
      d
    );
  }

  postReportImportProcess(
    d: EbookSalesReportImportRequest
  ): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `/authorized/ebook-sales/reports/process`,
      d
    );
  }

  listByFilter(
    d: EbookSalesFilter
  ): Observable<Response<PartialList<EbookSales>>> {
    let params = new HttpParams()
      .append('limit', String(d.limit))
      .append('offset', String(d.offset));
    d.pre_fix_type && (params = params.append('pre_fix_type', d.pre_fix_type));
    d.sales_route_code &&
      (params = params.append('sales_route_code', d.sales_route_code));
    d.sales_yyyymm_from &&
      (params = params.append('sales_yyyymm_from', d.sales_yyyymm_from));
    d.sales_yyyymm_to &&
      (params = params.append('sales_yyyymm_to', d.sales_yyyymm_to));
    d.ebook_product_code &&
      (params = params.append('ebook_product_code', d.ebook_product_code));
    return this.http.get<Response<PartialList<EbookSales>>>(
      `/authorized/ebook-sales/resources`,
      { params }
    );
  }

  deleteBySchedule(salesScheduleId: string): Observable<Response<any>> {
    return this.http.delete<Response<any>>(
      `/authorized/ebook-sales/schedules/${salesScheduleId}`
    );
  }

  listIssuesByFilter(
    d: EbookSalesImportIssueFilter
  ): Observable<Response<PartialList<EbookSalesImportIssue>>> {
    let params = new HttpParams()
      .append('limit', String(d.limit))
      .append('offset', String(d.offset));
    return this.http.get<Response<PartialList<EbookSalesImportIssue>>>(
      `/authorized/ebook-sales/issues/${d.schedule_id}`,
      { params }
    );
  }

  post(d: EbookSales): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `/authorized/ebook-sales/resources`,
      d
    );
  }

  delete(d: EbookSales): Observable<Response<any>> {
    return this.http.delete<Response<any>>(
      `/authorized/ebook-sales/resources/${d.id}`
    );
  }

  listStoreNames(): Observable<Response<string[]>> {
    return this.http.get<Response<string[]>>(
      `/authorized/ebook-sales/store-names`
    );
  }

  getMonthlyProductSalesRanking(
    yyyymm: string,
    limit: number = 5
  ): Observable<Response<EbookMonthlyProductSalesRanking[]>> {
    let params = new HttpParams().append('limit', String(limit));
    return this.http
      .get<Response<EbookMonthlyProductSalesRanking[]>>(
        `/authorized/ebook-sales-analysis/ranking/FIXED/${yyyymm}`,
        { params }
      )
      .pipe(
        tap((res) => {
          !res.data && (res.data = []);
        })
      );
  }

  getMonthlySales(year: string): Observable<Response<EbookMonthlySales[]>> {
    let params = new HttpParams().append('year', year);
    return this.http
      .get<Response<EbookMonthlySales[]>>(
        `/authorized/ebook-sales-analysis/sales`,
        { params }
      )
      .pipe(
        tap((res) => {
          !res.data && (res.data = []);
        })
      );
  }

  postMonthlyTransitionDownloadProcess(
    d: MonthlyTransitionDownloadRequest
  ): Observable<Response<string>> {
    return this.http.post<Response<string>>(
      `/authorized/ebook-sales-analysis/transition/export/process`,
      d
    );
  }

  getMonthlyTransitionDownloadURL(): Observable<Response<string>> {
    return this.http.get<Response<string>>(
      `/authorized/ebook-sales-analysis/transition/download-url`
    );
  }
}
