import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DateUtils } from './../../utils/date.utils';
import { Response } from './../models';

export interface Tenant {
  id?: string;
  name?: string;
  post_code?: string;
  address1?: string;
  address2?: string;
  division?: string;
  contact_person_name?: string;
  tel?: string;
  fax?: string;
  email?: string;
  bank_customer_code?: string;
  bank_customer_name?: string;
  bank_code?: string;
  bank_name?: string;
  bank_branch_code?: string;
  bank_branch_name?: string;
  bank_account_type?: string;
  bank_account_number?: string;
  bank_account_name?: string;
  start_month_of_fiscal_year?: number;
  rounding_type?: string;
  payment_threshold?: number;
  withholding_tax_target_type?: number;
  notifies_payment_when_under_threshold?: boolean;
  product_group_name_manage_type?: string;
  title_name_manage_type?: string;
  payee_address_manage_type?: string;

  withholding_tax_target_type_text?: string;
}

export const WITHHOLDING_TAX_TARGET_TYPES = {
  CALC_WITH_INCLUDED: '税込み支払額',
  CALC_WITH_EXCLUDED: '税抜き支払額',
};

export const WithholdingTaxTargetType = {
  CALC_WITH_INCLUDED: { id: 'CALC_WITH_INCLUDED', text: '税込み支払額' },
  CALC_WITH_EXCLUDED: { id: 'CALC_WITH_EXCLUDED', text: '税抜き支払額' },
};
export type WithholdingTaxTargetType =
  (typeof WithholdingTaxTargetType)[keyof typeof WithholdingTaxTargetType];

export const ProductGroupNameManageType = {
  TEXT: { id: 'TEXT', text: 'テキスト入力  (任意) ' },
  TEXT_REQUIRED: { id: 'TEXT_REQUIRED', text: 'テキスト入力  (必須) ' },
  // MEMO マスタ管理が実装されるまで未対応
  //MASTER: { id: 'MASTER', text: '作品マスタから選択 (任意) ' },
  //MASTER_REQUIRED: { id: 'MASTER_REQUIRED', text: '作品マスタから選択 (必須) ' },
} as const;
export type ProductGroupNameManageType =
  (typeof ProductGroupNameManageType)[keyof typeof ProductGroupNameManageType];

export const TitleNameManageType = {
  TEXT: { id: 'TEXT', text: 'テキスト入力  (任意) ' },
  TEXT_REQUIRED: { id: 'TEXT_REQUIRED', text: 'テキスト入力 (必須) ' },
  MASTER: { id: 'MASTER', text: 'タイトルマスタから選択  (任意) ' },
  MASTER_REQUIRED: {
    id: 'MASTER_REQUIRED',
    text: 'タイトルマスタから選択 (必須) ',
  },
} as const;
export type TitleNameManageType =
  (typeof TitleNameManageType)[keyof typeof TitleNameManageType];

export const PayeeAddressManageType = {
  REQUIRED: { id: 'REQUIRED', text: '必須' },
  NOT_REQUIRED: { id: 'NOT_REQUIRED', text: '任意' },
};
export type PayeeAddressManageType =
  (typeof PayeeAddressManageType)[keyof typeof PayeeAddressManageType];

@Injectable()
export class TenantsService {
  private cache: Tenant;

  constructor(private http: HttpClient) {}

  get(): Observable<Response<Tenant>> {
    if (this.cache) {
      return of({ success: true, data: this.cache });
    }
    return this.http.get<Response<Tenant>>(`/authorized/tenants/owned`).pipe(
      tap((res) => {
        this.formatResponse(res.data);
      })
    );
  }

  all(): Observable<Response<Tenant[]>> {
    return this.http.get<Response<Tenant[]>>(`/authorized/tenants/all`).pipe(
      tap((res) => {
        res.data.forEach((d) => this.formatResponse(d));
      })
    );
  }

  put(d: Tenant): Observable<Response<any>> {
    this.cache = null;
    return this.http.put<Response<any>>(`/authorized/tenants/owned`, d);
  }

  getMonthsOfFiscalYear(): Observable<string[]> {
    return this.get().pipe(
      map((res) => {
        const months = [];
        for (let i = 0; i < 12; i++) {
          let m = res.data.start_month_of_fiscal_year + i;
          if (m > 12) {
            m -= 12;
          }
          months.push(('0' + m).slice(-2));
        }
        return months;
      })
    );
  }

  getCurrentFiscalYear(): Observable<string> {
    return this.get().pipe(
      map((res) => {
        const yyyy = DateUtils.yyyy();
        const month = new Date().getMonth() + 1;
        if (month < res.data.start_month_of_fiscal_year) {
          return DateUtils.prev_yyyy(yyyy);
        } else {
          return yyyy;
        }
      })
    );
  }

  private formatResponse(resp: Tenant) {
    if (!resp) {
      return;
    }
    resp.withholding_tax_target_type_text =
      WITHHOLDING_TAX_TARGET_TYPES[resp.withholding_tax_target_type];
  }

  isProductGroupNameRequired(tenat: Tenant) {
    return (
      tenat.product_group_name_manage_type ==
      ProductGroupNameManageType.TEXT_REQUIRED.id
    );
  }

  isTitleNameRequired(tenat: Tenant) {
    return (
      tenat.title_name_manage_type == TitleNameManageType.TEXT_REQUIRED.id ||
      tenat.title_name_manage_type == TitleNameManageType.MASTER_REQUIRED.id
    );
  }

  useTitleMaster(tenat: Tenant) {
    return (
      tenat.title_name_manage_type == TitleNameManageType.MASTER.id ||
      tenat.title_name_manage_type == TitleNameManageType.MASTER_REQUIRED.id
    );
  }
}
