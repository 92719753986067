import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PartialList, Response } from '../models';

export interface BankBranch {
  bank_code?: string;
  code?: string;
  name?: string;
  kana?: string;
  name_halfwidth?: string;
}

@Injectable()
export class BankBranchesService {

  constructor(
    private http: HttpClient,
  ) { }

  listByBankCodeAndCodeOrNameLike(bank_code, code, name: string): Observable<Response<BankBranch[]>> {
    let params = new HttpParams().append('fuzzy', '1');
    bank_code && (params = params.append('bank_code', bank_code));
    code && (params = params.append('code', code));
    name && (params = params.append('name', name));
    return this.http.get<Response<BankBranch[]>>(`/authorized/bank-branches`, { params }).pipe(
      tap(res => res.data)
    );
  }

  get(bank_code, code: string): Observable<Response<any>> {
    return this.http.get<Response<any>>(`/authorized/bank-branches/${bank_code}/${code}`);
  }
}
