import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Response } from './../models';
import { DateUtils } from './../../utils/date.utils';

export interface SharedSalesRoute {
  code?: string;
  end_date?: Date;
  name?: string;
  kana?: string;
  remarks?: string;
  type?: string;
  company_name?: string;
  post_code?: string;
  address?: string;
  tel?: string;
  fax?: string;
  email?: string;
  currency_code?: string;
  payment_site_month?: number;
  read_unlimited_type?: number;
}

export interface SalesRoute extends SharedSalesRoute {
  default_deposit_rate?: number;
  use_preliminary?: boolean;
  sales_import_dd?: number;
  edit_level?: number;

  end_date_before_change?: Date;
}

export const RouteType = {
  AGENCY: { id: "AGENCY", text: "取次" },
  STORE: { id: "STORE", text: "書店" }
}
export type RouteType = typeof RouteType[keyof typeof RouteType];

// PartialEdited を「既設（一部編集）」にすると売上取込設定のカスタマイズと紛らわしいので、「既設」で揃える
export const EDIT_LEVEL = {
  1: '既設', 2: '既設', 3: '独自'
};

export const EditLevel = {
  NoEdited: { id: 1, text: "既設" },
  PartialEdited: { id: 2, text: "既設" },
  FullEdited: { id: 3, text: "独自" }
}
export type EditLevel = typeof EditLevel[keyof typeof EditLevel];

@Injectable()
export class SalesRoutesService {

  constructor(
    private http: HttpClient,
  ) { }

  salesRouteFrom(src: SharedSalesRoute): SalesRoute {
    return {
      code: src.code,
      end_date: src.end_date,
      name: src.name,
      kana: src.kana,
      remarks: src.remarks,
      type: src.type,
      company_name: src.company_name,
      post_code: src.post_code,
      address: src.address,
      tel: src.tel,
      fax: src.fax,
      email: src.email,
      currency_code: src.currency_code,
      payment_site_month: src.payment_site_month,
      sales_import_dd: 1,
      default_deposit_rate: 0,
      edit_level: 1,
    };
  }

  private formatResponse(resp: SalesRoute[] | SharedSalesRoute[]) {
    resp.forEach(e => {
      e.end_date && (e.end_date = new Date(e.end_date));
    });
  }

  private distinctByCode(resp: SalesRoute[] | SharedSalesRoute[]) {
    const codeMap: { [key: string]: SharedSalesRoute; } = {};
    resp.forEach(a => {
      const b = codeMap[a.code];
      if (!b) {
        codeMap[a.code] = a;
      } else {
        if (a.end_date >= new Date() && b.end_date < new Date()) {
          codeMap[a.code] = a;
        }
        if (a.end_date < b.end_date && a.end_date >= new Date()) {
          codeMap[a.code] = a;
        }
      }
    });
    resp = Object.keys(codeMap).map(k => codeMap[k]);
    return resp;
  }

  listSharedRoutes(): Observable<Response<SharedSalesRoute[]>> {
    return this.http.get<Response<SharedSalesRoute[]>>(`/authorized/sales-routes/shared`).pipe(
      tap(res => this.formatResponse(res.data))
    );
  }

  listDistinctSharedRoutes(): Observable<Response<SharedSalesRoute[]>> {
    return this.listSharedRoutes().pipe(
      map(res => {
        res.data = this.distinctByCode(res.data);
        return res;
      })
    );
  }

  listAll(): Observable<Response<SalesRoute[]>> {
    return this.http.get<Response<SalesRoute[]>>(`/authorized/sales-routes/owned`).pipe(
      tap(res => this.formatResponse(res.data))
    );
  }

  /**
   * コードの重複が無い販路のリストを返す。
   */
  listDistinctAll(): Observable<Response<SalesRoute[]>> {
    return this.listAll().pipe(
      map(res => {
        res.data = this.distinctByCode(res.data);
        return res;
      })
    );
  }

  put(d: SalesRoute): Observable<Response<SalesRoute>> {
    return this.http.put<Response<SalesRoute>>(`/authorized/sales-routes/owned`, d);
  }

  post(d: SalesRoute): Observable<Response<SalesRoute>> {
    return this.http.post<Response<SalesRoute>>(`/authorized/sales-routes/owned`, d);
  }

  split(d: SalesRoute, startDate: Date): Observable<Response<Boolean>> {
    const params = new HttpParams().append("start_date", DateUtils.slashed_yyyymmdd(DateUtils.yyyymmdd(startDate)));
    return this.http.post<Response<Boolean>>(`/authorized/sales-routes/split`, d, {params});
  }

  delete(d: SalesRoute): Observable<Response<any>> {
    const end_date_str = DateUtils.yyyymmdd(new Date(d.end_date));
    return this.http.delete<Response<any>>(`/authorized/sales-routes/owned/${d.code}/${end_date_str}`);
  }
}
