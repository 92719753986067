import { environment } from './../environments/environment';
import { Component, OnInit } from '@angular/core';
import { AuthenticationState, AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  env = environment;
  authenticated: boolean;

  constructor(
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.authenticated = this.auth.state === AuthenticationState.Authenticated;
    this.auth.onStateChanged.subscribe((state: AuthenticationState) => {
      const authenticated = state === AuthenticationState.Authenticated;
      this.authenticated = authenticated;
    });
  }
}
