import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response, PartialList } from './models';

export interface User {
  id: string;
  email: string;
  name: string;
  admin_flg: number;
  role_id: string;
  temporary_password_flg: number;
  invalid_flg: number;
}

export interface Role {
  id: string;
	name: string;
}

export interface UserInput {
  id?: string;
  email?: string;
  name?: string;
  admin_flg?: number;
  role_id?: string;
  invalid_flg?: number;
  // Role新規登録用
  role_name?: string;
}

export interface UserInputResponse extends Object {
  user: User;
  error_column: string;
}

export interface UserListResponse extends PartialList<string>{}

@Injectable()
export class UsersService {

  constructor(
    private http: HttpClient,
  ) { }

  emptyUser(): User {
    return {
      id: '',
      email: '',
      name: '',
      admin_flg: 0, // このままだとエラーなので、adminか、role必須
      role_id: '',
      temporary_password_flg: 1,
      invalid_flg: 0,
    };
  }

  listAll(): Observable<Response<User[]>> {
    return this.http.get<Response<User[]>>(`/authorized/users`);
  }

  ListByRoleId(roleId: string, limit: number): Observable<Response<UserListResponse>> {
    return this.http.get<Response<UserListResponse>>(`/authorized/users/list/${roleId}?limit=${limit}`);
  }

  post(d: UserInput): Observable<Response<UserInputResponse>> {
    return this.http.post<Response<UserInputResponse>>(`/authorized/users`, d);
  }

  put(d: UserInput): Observable<Response<UserInputResponse>> {
    return this.http.put<Response<UserInputResponse>>(`/authorized/users`, d);
  }
}