import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as jwt_decode from "jwt-decode";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { Response } from '../services/models';
import { Role } from '../services/users.service';

const StorageKey = `md-erp.${environment.name}.TOKEN`;

export interface UserContext {
  tenant_id: string;
  id: string;
  name: string;
  email: string;
  role_id: string;
  admin_flg: boolean;
  is_support: boolean;
  tenant_id_support: string;
}

export enum AuthenticationState {
  Unauthenticated = 'Unauthenticated',
  NewPasswordRequired = 'NewPasswordRequired',
  Authenticated = 'Authenticated',
}

export enum LoginResult {
  Succeeded = 'Succeeded',
  SupportUserLogin = 'SupportUserLogin',
  NewPasswordRequired = 'NewPasswordRequired',
  NotAuthorized = 'NotAuthorized',
  CodeMissmatch = 'CodeMissmatch',
  CodeExpired = 'CodeExpired',
  Failed = 'Failed',
}

export enum PaswordResetResult {
  Succeeded = 'Succeeded',
  ResetMailSent = 'ResetMailSent',
  UserNotFound = 'UserNotFound',
  CodeMissmatch = 'CodeMissmatch',
  CodeExpired = 'CodeExpired',
  Failed = 'Failed',
}

interface AuthenticationResponse {
  result: LoginResult;
  token: string;
}

export interface Authority {
  role_id: string;
  auth_id_map?: Object;
}

export interface SharedAuthority {
  id: string;
  module: string;
  title: string;
  order: number;
}

export interface AuthorityModifyRequest extends Object {
  authority: Authority;
  role_name?: string;
}

@Injectable()
export class AuthService {

  private jwt: string;
  private userContext: UserContext;
  private stateChange = new BehaviorSubject<AuthenticationState>(null);
  private contextChange = new BehaviorSubject<UserContext>(null);

  get state(): AuthenticationState {
    if (!this.jwt) {
      this.checkStorage();
    }
    return this.jwt ? AuthenticationState.Authenticated : AuthenticationState.Unauthenticated;
  }

  get token(): string {
    if (!this.jwt) {
      this.checkStorage();
    }
    return this.jwt || '';
  }

  get user(): UserContext {
    if (!this.jwt) {
      this.checkStorage();
    }
    return this.userContext || null;
  }

  get onStateChanged(): BehaviorSubject<AuthenticationState> {
    return this.stateChange;
  }

  get onContextChanged(): BehaviorSubject<UserContext> {
    return this.contextChange;
  }

  constructor(private http: HttpClient) {
    this.checkStorage();
    if (!this.jwt) {
      this.onStateChanged.next(AuthenticationState.Unauthenticated);
    } else {
      this.onStateChanged.next(AuthenticationState.Authenticated);
      setTimeout(() => {
        this.http.post<boolean>(`/authorized/ping`, {}).subscribe({
          next: _ => { }
        });
      });
    }
  }

  login(email: string, password: string): Observable<LoginResult> {
    return this.http.post<AuthenticationResponse>(
      `/public/login`, { email: email, password: password })
      .pipe(
        map(resp => {
          switch (resp.result) {
            case LoginResult.Succeeded:
              if (!this.checkResponse(resp.token)) {
                return LoginResult.Failed;
              }
              window.localStorage.setItem(StorageKey, this.jwt);
              this.stateChange.next(AuthenticationState.Authenticated);
              return resp.result;

            case LoginResult.SupportUserLogin:
              if (!this.checkResponse(resp.token)) {
                return LoginResult.Failed;
              }
              window.localStorage.setItem(StorageKey, this.jwt);
              // this.stateChange.nextだけ、後回し
              return resp.result;

            case LoginResult.NewPasswordRequired:
              if (!this.checkResponse(resp.token)) {
                return LoginResult.Failed;
              }
              return resp.result;

            default:
              return resp.result;
          }
        }),
        catchError(_ => {
          return of(LoginResult.Failed);
        })
      );
  }

  refreshContextAsSupport(tenantId: string): Observable<boolean> {
    return this.http.post<AuthenticationResponse>(`/token/support`, { "tenant_id": tenantId })
      .pipe(
        map(resp => {
          switch (resp.result) {
            case LoginResult.Succeeded:
              if (!this.checkResponse(resp.token)) {
                throw new Error('Failed to refresh context!');
              }
              window.localStorage.setItem(StorageKey, this.jwt);
              this.contextChange.next(this.user);
              return true;

            default:
              return false;
          }
        })
      );
  }

  // NOTE 使ってない？
  refreshContext(): void {
    this.http.post<string>(`/authorized/refresh-token`, null)
      .subscribe(token => {
        if (!this.checkResponse(token)) {
          throw new Error('Failed to refresh context!');
        }
        window.localStorage.setItem(StorageKey, this.jwt);
        this.contextChange.next(this.user);
      });
  }

  logout(): Observable<boolean> {
    return this.http.post<boolean>(`/authorized/logout`, null)
      .pipe(
        finalize(() => {
          this.clear();
          this.stateChange.next(AuthenticationState.Unauthenticated);
        })
      );
  }

  logoutForcibly() {
    this.clear();
    this.stateChange.next(AuthenticationState.Unauthenticated);
  }

  updateNewPassword(password: string): Observable<boolean> {
    return this.http.post<boolean>(`/authorized/new-password`, { password })
      .pipe(
        finalize(() => {
          window.localStorage.setItem(StorageKey, this.jwt);
          this.stateChange.next(AuthenticationState.Authenticated);
        })
      );
  }

  forgotPassword(email: string): Observable<PaswordResetResult> {
    return this.http.post<PaswordResetResult>(`/public/forgot-password`, { email: email });
  }

  resetPassword(code: string, email: string, password: string): Observable<PaswordResetResult> {
    return this.http.post<PaswordResetResult>(`/public/reset-password`, {
      code: code,
      email: email,
      password: password
    });
  }

  getAllShared(): Observable<Response<SharedAuthority[]>> {
    return this.http.get<Response<SharedAuthority[]>>(`/authorized/authorities/shared`);
  }

  private cachedAuthorityResp: Response<Authority>;
  getAuthorities(): Observable<Response<Authority>> {
    // キャッシュから取り出す
    if (this.cachedAuthorityResp) {
      return of(this.cachedAuthorityResp)
    }
    return this.requestAuthorities(this.user.role_id, this.user.admin_flg).pipe(
      tap(resp => {
        // メモリにキャッシュ
        this.cachedAuthorityResp = resp;
      })
    );
  }

  getAuthoritiesWithRole(roleId: string): Observable<Response<Authority>> {
    return this.requestAuthorities(roleId, false);
  }

  private requestAuthorities(roleId: string, adminFlg: boolean): Observable<Response<Authority>> {
    return this.http.get<Response<Authority>>(`/authorized/authorities?role_id=${roleId}&admin_flg=${adminFlg}`);
  }

  post(req: AuthorityModifyRequest): Observable<Response<Role>> {
    return this.http.post<Response<Role>>(`/authorized/authorities`, req);
  }

  delete(roleId: string): Observable<Response<any>> {
    return this.http.delete<Response<any>>(`/authorized/authorities?role_id=${roleId}`);
  }

  static passwordValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;
    return !/^(?=.*?[a-z])(?=.*?\d)(?=.*?[!-\/:-@[-`{-~])[!-~]{8,128}$/i.test(control.value) ? { invalid: true } : null;
  }

  private checkResponse(jwt: string): boolean {
    if (!this.extractClaim(jwt)) {
      return false;
    }
    this.jwt = jwt;
    return true;
  }

  private checkStorage(): boolean {
    const jwt = window.localStorage.getItem(StorageKey);
    return this.checkResponse(jwt);
  }

  private extractClaim(jwt: string): boolean {
    if (!jwt) {
      console.log('JWT not present.');
      return false;
    }

    try {
      const decoded = jwt_decode(jwt);
      this.userContext = {
        tenant_id: decoded.tenant_id,
        id: decoded.user_id,
        name: decoded.user_name,
        email: decoded.user_email,
        role_id: decoded.role_id,
        admin_flg: decoded.admin_flg,
        is_support: decoded.is_support,
        tenant_id_support: decoded.tenant_id_support,
      };
    } catch (e) {
      console.error('Failed to parse JWT!', e);
      this.clear();
      throw e;
    }
    return true;
  }

  private clear() {
    window.localStorage.removeItem(StorageKey);
    this.jwt = null;
    this.cachedAuthorityResp = null;
  }
}