import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  ExcelUploadRequest,
  SettingImportStatusBase,
  TemporaryImportedMaster,
} from '../setting-import/setting-import.service';
import { ArrayUtils } from './../../utils/array.utils';
import {
  FileUploadParams,
  ListFilter,
  PartialList,
  Response,
  StdMst,
} from './../models';

export interface EbookProduct extends StdMst {
  name?: string;
  kana?: string;
  product_group_name?: string;
  title_name?: string;
  title_serial_number?: number;
  publishing_type?: string;
  content_volume?: string;
  author_names?: string;
  current_retail_price?: number;
  genre1?: string;
  genre2?: string;
  label?: string;
  published_media?: string;
  analysis_item1?: string;
  analysis_item2?: string;
  isbn13?: string;
  jdcn?: string;
  delivery_date?: Date;
  remarks?: string;
  output_code?: string;
  details?: EbookProductDetail[];

  payeeCodesForView?: GroupedPayeeCodes[];
}

export interface GroupedPayeeCodes {
  endDate: Date;
  payeeCodes: string[];
}

export interface EbookProductDetail extends StdMst {
  id?: string;
  end_date?: Date;
  type: 'PAYEE';
  payee_code?: string;
  payee_name?: string;
}

export interface EbookProductFilter extends ListFilter {
  code?: string;
  name?: string;
  kana?: string;
  isbn13?: string;
  jdcn?: string;
  title_name?: string;
  last_imported?: boolean;
}

export interface ListTempImportedProductsResponse {
  has_error?: boolean;
  error_msg?: string;
  masters?: TemporaryImportedMaster<EbookProduct>[];
  num_of_total?: number;
}

@Injectable()
export class EbookProductsService {
  constructor(private http: HttpClient) {}

  private formatResponse(resp: EbookProduct[]) {
    resp &&
      resp.forEach((e) => {
        e.delivery_date && (e.delivery_date = new Date(e.delivery_date));
        this.formatResponseDetails(e.details);
      });
  }

  emptyType(): EbookProduct {
    return {
      code: null,
      name: null,
      details: [],
    };
  }

  listByFilter(
    d: EbookProductFilter
  ): Observable<Response<PartialList<EbookProduct>>> {
    let params = new HttpParams()
      .append('limit', String(d.limit))
      .append('offset', String(d.offset));
    d.code && (params = params.append('code', d.code));
    d.name && (params = params.append('name', d.name));
    d.kana && (params = params.append('kana', d.kana));
    d.isbn13 && (params = params.append('isbn13', d.isbn13));
    d.jdcn && (params = params.append('jdcn', d.jdcn));
    d.title_name && (params = params.append('title_name', d.title_name));
    d.last_imported &&
      (params = params.append('last_imported', String(d.last_imported)));
    return this.http
      .get<Response<PartialList<EbookProduct>>>(`/authorized/ebook-products`, {
        params,
      })
      .pipe(tap((res) => this.formatResponse(res.data.list)));
  }

  listByCodes(d: string[]): Observable<Response<EbookProduct[]>> {
    return forkJoin(
      ArrayUtils.toChunks(d).map((codes) =>
        this.http.post<Response<EbookProduct[]>>(
          `/authorized/ebook-products/codes`,
          codes
        )
      )
    ).pipe(
      map((respArr) => {
        if (respArr.some((e) => !e.success)) {
          return { success: false, data: null };
        }

        const merged = [];
        respArr.forEach((e) => {
          this.formatResponse(e.data);
          merged.push(...e.data);
        });
        return { success: true, data: merged };
      })
    );
  }

  listByCodeOrNameLike(
    c: string,
    n: string
  ): Observable<Response<EbookProduct[]>> {
    let params = new HttpParams().append('fuzzy', '1');
    c && (params = params.append('code', c));
    n && (params = params.append('name', n));
    return this.http
      .get<Response<EbookProduct[]>>(`/authorized/ebook-products`, { params })
      .pipe(tap((res) => this.formatResponse(res.data)));
  }

  listProductGroupLike(keyword: string): Observable<Response<string[]>> {
    return this.http.get<Response<string[]>>(
      `/authorized/ebook-products/product-groups`,
      { params: { keyword: keyword } }
    );
  }

  listTitleLike(keyword: string): Observable<Response<string[]>> {
    return this.http.get<Response<string[]>>(
      `/authorized/ebook-products/titles`,
      { params: { keyword: keyword } }
    );
  }

  getDetails(
    code: string,
    baseDate: Date
  ): Observable<Response<EbookProductDetail[]>> {
    let params = new HttpParams();
    code && (params = params.append('code', code));
    baseDate && (params = params.append('base_date', baseDate.toISOString()));
    return this.http
      .get<Response<EbookProductDetail[]>>(
        `/authorized/ebook-products/details`,
        { params }
      )
      .pipe(tap((res) => this.formatResponseDetails(res.data)));
  }

  private formatResponseDetails(data: EbookProductDetail[]) {
    data &&
      data.forEach((d) => {
        d.end_date = new Date(d.end_date);
      });
  }

  // add用
  post(d: EbookProduct): Observable<Response<any>> {
    return this.http.post<Response<any>>(`/authorized/ebook-products`, d);
  }

  // upd用
  put(d: EbookProduct): Observable<Response<any>> {
    return this.http.put<Response<any>>(`/authorized/ebook-products`, d);
  }

  // MEMO 現在、使われていない（frontでのエクセル取り込みを廃止したため）。邪魔になったら消してよし
  postBulk(d: EbookProduct[]): Observable<Response<boolean>> {
    return forkJoin(
      ArrayUtils.toChunks(d).map((prods) =>
        this.http.post<Response<boolean>>(`/authorized/ebook-products`, prods)
      )
    ).pipe(
      map((respArr) => {
        if (respArr.some((e) => !e.success)) {
          return { success: false, data: null };
        }
        return { success: true, data: null };
      })
    );
  }

  listTempImportedMasters(
    target: string,
    limit: number,
    offset: number
  ): Observable<Response<ListTempImportedProductsResponse>> {
    let params = new HttpParams()
      .append('target', target)
      .append('limit', String(limit))
      .append('offset', String(offset));
    return this.http
      .get<Response<ListTempImportedProductsResponse>>(
        `/authorized/ebook-products/temp-imported`,
        { params }
      )
      .pipe(
        tap((d) => {
          d.data.masters.forEach((m) => {
            this.formatResponse([m.data]);
            if (m.comparison) {
              this.formatResponse([m.comparison]);
            }
          });
        })
      );
  }

  delete(d: EbookProduct): Observable<Response<any>> {
    return this.http.delete<Response<any>>(
      `/authorized/ebook-products/${d.code}`
    );
  }

  getExcelUploadURL(
    val: ExcelUploadRequest
  ): Observable<Response<FileUploadParams>> {
    return this.http.post<Response<FileUploadParams>>(
      `/authorized/ebook-products/excel/upload-url`,
      val
    );
  }

  processExcelImport(val: ExcelUploadRequest): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `/authorized/ebook-products/excel/import/process`,
      val
    );
  }

  processExcelImportFix(): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `/authorized/ebook-products/excel/import/fix`,
      null
    );
  }

  getImportStatus(): Observable<Response<SettingImportStatusBase>> {
    return this.http.get<Response<SettingImportStatusBase>>(
      `/authorized/ebook-products/excel/import/status`
    );
  }

  processExcelExport(d: EbookProductFilter): Observable<Response<string>> {
    return this.http.post<Response<string>>(
      `/authorized/ebook-products/excel/export/process`,
      d
    );
  }

  getExcelDownloadURL(): Observable<Response<string>> {
    return this.http.get<Response<string>>(
      `/authorized/ebook-products/excel/download-url`
    );
  }

  listPublishingTypes(): Observable<Response<string[]>> {
    return this.http.get<Response<string[]>>(
      `/authorized/ebook-products/publishing-types`
    );
  }

  listContentVolumes(): Observable<Response<string[]>> {
    return this.http.get<Response<string[]>>(
      `/authorized/ebook-products/content-volumes`
    );
  }
}

export function GroupPayeeCodesByEndDate(
  details: EbookProductDetail[]
): GroupedPayeeCodes[] {
  if (!details || details.length === 0) {
    return [];
  }
  details.sort((a, b) => a.end_date.valueOf() - b.end_date.valueOf());

  const forView: GroupedPayeeCodes[] = [];
  details.forEach((d) => {
    const v = forView.find(
      (view) => view.endDate.valueOf() === d.end_date.valueOf()
    );
    if (v) {
      v.payeeCodes.push(d.payee_code);
    } else {
      forView.push({ endDate: d.end_date, payeeCodes: [d.payee_code] });
    }
  });
  return forView;
}

export const EBOOK_PRODUCT_ATTRS = [
  {
    id: 'code',
    name: '商品コード',
  },
  {
    id: 'name',
    name: '商品名',
  },
  {
    id: 'kana',
    name: '商品名カナ',
  },
  {
    id: 'product_group_name',
    name: '作品名',
  },
  {
    id: 'title_name',
    name: 'タイトル名',
  },
  {
    id: 'title_serial_number',
    name: 'タイトル連番',
  },
  {
    id: 'publishing_type',
    name: '発行形態',
  },
  {
    id: 'content_volume',
    name: 'コンテンツボリューム',
  },
  {
    id: 'payee_codes',
    name: '支払先',
  },
  {
    id: 'author_names',
    name: '著者名',
  },
  {
    id: 'genre1',
    name: 'ジャンル1',
  },
  {
    id: 'genre2',
    name: 'ジャンル2',
  },
  {
    id: 'label',
    name: 'レーベル',
  },
  {
    id: 'published_media',
    name: '掲載誌',
  },
  {
    id: 'analysis_item1',
    name: '分析用予備項目1',
  },
  {
    id: 'analysis_item2',
    name: '分析用予備項目2',
  },
  {
    id: 'isbn13',
    name: '底本ISBN',
  },
  {
    id: 'jdcn',
    name: 'JDCN',
  },
  {
    id: 'delivery_date',
    name: '配信日',
  },
  {
    id: 'remarks',
    name: '備考',
  },
  {
    id: 'current_retail_price',
    name: '希望小売価格',
  },
  {
    id: 'output_code',
    name: '外部連携用コード',
  },
];
