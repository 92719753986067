<form [formGroup]="form" autocomplete="off">

  <div class="entry">
    <p>
      登録されているメールアドレスを入力してください。
      <br>
      パスワードを再設定するためのコードを記載したメールをお送りします。
    </p>

    <mat-form-field appearance="outline">
      <mat-label>メールアドレス</mat-label>
      <input matInput placeholder="メールアドレス" formControlName="email" spellcheck="false">
      <mat-error *ngIf="email.hasError('required')">メールアドレスを入力してください</mat-error>
      <mat-error *ngIf="email.hasError('email')">正しい形式のメールアドレスを入力してください</mat-error>
      <mat-error *ngIf="email.hasError('invalid')">有効なメールアドレスを入力してください</mat-error>
    </mat-form-field>

    <span></span>

    <p class="warning" *ngIf="result == 'Failed'">
      リクエストを受け付けることができませんでした<br>管理者へお問い合わせください
    </p>
  </div>

  <div class="action">
    <a mat-button color="primary" (click)="onGoToLoginClick()">ログイン画面ヘ</a>
    <button mat-raised-button color="primary" [disabled]="processing" (click)="onSubmit()">送信</button>
  </div>

</form>