import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FailedResponseData, ListFilter, PartialList, RecoupmentUnit, Response, StdModel } from '../models';

export interface Prepayment extends StdModel {
  payment_detail_id?: string;
  booked_yyyymmdd?: string;
  payment_yyyymm?: string;
  name?: string;
  tax_excluded_amount?: number;
  payee_code?: string;
  payee_name?: string;
  recoupment_unit?: string;
  product_group_name?: string;
  title_name?: string;
  ebook_product_code?: string;
  ebook_product_name?: string;
  recoupment_balance?: number;
  recoupment_start_yyyymm?: string;
  recoupment_end_yyyymm?: string;
  // 表示用
  recoupment_unit_text?: string;
  recoupment_target?: string;
  expiration_text?: string;
}

export interface PrepaymentValidationTarget {
  prepayment?: Prepayment;
  is_add?: boolean;
}

export interface PrepaymentFilter extends ListFilter {
  booked_yyyymmdd_from?: string;
  booked_yyyymmdd_to?: string;
  prepayment_state?: string;
  recoupment_unit?: string;
  payee_code?: string;
  payment_yyyymm?: string;
  ebook_product_code?: string;
  ebook_product_name?: string;
  title_name?: string;
  product_group_name?: string;
  recoupment_balance_from?: string;
  recoupment_balance_to?: string;
  recoupment_start_yyyymm_from?: string;
  recoupment_start_yyyymm_to?: string;
  recoupment_end_yyyymm_from?: string;
  recoupment_end_yyyymm_to?: string;

  payee_name?: string;
  prepayment_state_text?: string;
  recoupment_unit_text?: string;
}
@Injectable()
export class PrepaymentsService {

  constructor(
    private http: HttpClient,
  ) { }

  private formatResponse(resp: Prepayment[]) {
    resp.forEach(e => {
      e.recoupment_unit_text = RecoupmentUnit[e.recoupment_unit].text;
      if (e.recoupment_unit == RecoupmentUnit.PRODUCT_GROUP.id) {
        e.recoupment_target = e.product_group_name;
      } else if (e.recoupment_unit == RecoupmentUnit.TITLE.id) {
        e.recoupment_target = e.title_name;
      } else if (e.recoupment_unit == RecoupmentUnit.PRODUCT.id) {
        e.recoupment_target = e.ebook_product_name;
      }
      if (e.recoupment_start_yyyymm && e.recoupment_end_yyyymm) {
        e.expiration_text = e.recoupment_start_yyyymm.slice(0, 4) + "/" + e.recoupment_start_yyyymm.slice(4, 6) + "〜" + e.recoupment_end_yyyymm.slice(0, 4) + "/" + e.recoupment_end_yyyymm.slice(4, 6);
      } else if (e.recoupment_start_yyyymm && !e.recoupment_end_yyyymm) {
        e.expiration_text = e.recoupment_start_yyyymm.slice(0, 4) + "/" + e.recoupment_start_yyyymm.slice(4, 6) + "〜";
      } else if (!e.recoupment_start_yyyymm && e.recoupment_end_yyyymm) {
        e.expiration_text = "〜" + e.recoupment_end_yyyymm.slice(0, 4) + "/" + e.recoupment_end_yyyymm.slice(4, 6);
      } else {
        e.expiration_text = "";
      }
    });
  }

  listByFilter(d: PrepaymentFilter): Observable<Response<PartialList<Prepayment>>> {
    let params = new HttpParams()
      .append('limit', String(d.limit))
      .append('offset', String(d.offset));
    d.booked_yyyymmdd_from && (params = params.append('booked_yyyymmdd_from', d.booked_yyyymmdd_from));
    d.booked_yyyymmdd_to && (params = params.append('booked_yyyymmdd_to', d.booked_yyyymmdd_to));
    d.prepayment_state && (params = params.append('prepayment_state', d.prepayment_state));
    d.recoupment_unit && (params = params.append('recoupment_unit', d.recoupment_unit));
    d.payee_code && (params = params.append('payee_code', d.payee_code));
    d.payment_yyyymm && (params = params.append('payment_yyyymm', d.payment_yyyymm));
    d.ebook_product_code && (params = params.append('ebook_product_code', d.ebook_product_code));
    d.title_name && (params = params.append('title_name', d.title_name));
    d.product_group_name && (params = params.append('product_group_name', d.product_group_name));
    d.recoupment_balance_from != null && (params = params.append('recoupment_balance_from', d.recoupment_balance_from));
    d.recoupment_balance_to != null && (params = params.append('recoupment_balance_to', d.recoupment_balance_to));
    d.recoupment_start_yyyymm_from && (params = params.append('recoupment_start_yyyymm_from', d.recoupment_start_yyyymm_from));
    d.recoupment_start_yyyymm_to && (params = params.append('recoupment_start_yyyymm_to', d.recoupment_start_yyyymm_to));
    d.recoupment_end_yyyymm_from && (params = params.append('recoupment_end_yyyymm_from', d.recoupment_end_yyyymm_from));
    d.recoupment_end_yyyymm_to && (params = params.append('recoupment_end_yyyymm_to', d.recoupment_end_yyyymm_to));

    return this.http.get<Response<PartialList<Prepayment>>>(`/authorized/prepayments/resources`, { params })
      .pipe(
        tap(res => this.formatResponse(res.data.list))
      );
  }
  validate(data: PrepaymentValidationTarget): Observable<Response<FailedResponseData>> {
    return this.http.post<Response<FailedResponseData>>(`/authorized/prepayments/validation`, data);
  }

  duplicationCheck(data: Prepayment): Observable<Response<Prepayment[]>> {
    return this.http.post<Response<Prepayment[]>>(`/authorized/prepayments/duplication-check`, data).pipe(
      tap(resp => {
        if (resp.data.length > 0) {
          this.formatResponse(resp.data);
        }
      })
    );
  }

  put(data: Prepayment): Observable<Response<any>> {
    return this.http.put<Response<any>>(`/authorized/prepayments`, data);
  }

  post(data: Prepayment): Observable<Response<any>> {
    return this.http.post<Response<any>>(`/authorized/prepayments`, data);
  }

  delete(id: string): Observable<Response<any>> {
    return this.http.delete<Response<any>>(`/authorized/prepayments/resources/${id}`);
  }
}
