import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class DateUtils {
  static parseForExcelEntry(src: Date | number | any): Date {
    if (src == null) {
      return null;
    }

    if (src instanceof Date) {
      return src;
    }
    if (typeof src === 'number') {
      // MySQLのDATE型でサポートしていない範囲はエラーとする
      if (src < -693958 || src > 2958466) {
        return new Date('invalid date');
      }
      const d = new Date(1900, 0, 1);
      d.setDate(src - 1);
      return d;
    }

    // Invalid date でもそのまま返す
    return new Date(src);
  }

  static yyyyList(): string[] {
    const list = [];
    let year = new Date().getFullYear() + 1;
    while (year >= 2011) {
      list.push(`${year}`);
      --year;
    }
    return list;
  }

  static mmList(): string[] {
    return [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];
  }

  static mmListAsNumber(): number[] {
    return this.mmList().map((mm) => parseInt(mm));
  }

  static yyyy(date?: Date): string {
    const d = date || new Date();
    return `${d.getFullYear()}`;
  }

  static mm(date?: Date): string {
    const d = date || new Date();
    return `${('0' + (d.getMonth() + 1)).slice(-2)}`;
  }

  static yyyymm(date?: Date): string {
    const d = date || new Date();
    return `${d.getFullYear()}${('0' + (d.getMonth() + 1)).slice(-2)}`;
  }

  static yyyymmdd(date?: Date): string {
    const d = date || new Date();
    return `${d.getFullYear()}${('0' + (d.getMonth() + 1)).slice(-2)}${(
      '0' + d.getDate()
    ).slice(-2)}`;
  }

  static firstDateOfMonth_yyyymmdd(date?: Date): string {
    const d = date || new Date();
    d.setDate(1);
    return this.yyyymmdd(d);
  }

  static yyyymmddToDate(yyyymmdd: string): Date {
    return new Date(
      parseInt(yyyymmdd.slice(0, 4)),
      parseInt(yyyymmdd.slice(4, 6)) - 1,
      parseInt(yyyymmdd.slice(6, 8))
    );
  }

  static extract_yyyy(yyyymmdd: string): string {
    return yyyymmdd.slice(0, 4);
  }

  static extract_mm(yyyymmdd: string): string {
    return yyyymmdd.slice(4, 6);
  }

  static extract_dd(yyyymmdd: string): string {
    return yyyymmdd.slice(6, 8);
  }

  static slashed_yyyymmdd(yyyymmdd: string): string {
    if (yyyymmdd.length >= 8) {
      return `${this.extract_yyyy(yyyymmdd)}/${this.extract_mm(
        yyyymmdd
      )}/${this.extract_dd(yyyymmdd)}`;
    }
    if (yyyymmdd.length >= 6) {
      return `${this.extract_yyyy(yyyymmdd)}/${this.extract_mm(yyyymmdd)}`;
    }
    return yyyymmdd;
  }

  static slashed_yyyymmdd_byDate(date: Date): string {
    return DateUtils.slashed_yyyymmdd(DateUtils.yyyymmdd(date));
  }

  static prev_yyyymm(yyyymm: string): string {
    const d = new Date(
      parseInt(yyyymm.slice(0, 4)),
      parseInt(yyyymm.slice(4, 6)) - 1
    );
    d.setMonth(d.getMonth() - 1);
    return this.yyyymm(d);
  }

  static next_yyyymm(yyyymm: string): string {
    const d = new Date(
      parseInt(yyyymm.slice(0, 4)),
      parseInt(yyyymm.slice(4, 6)) - 1
    );
    d.setMonth(d.getMonth() + 1);
    return this.yyyymm(d);
  }

  static prev_yyyy(yyyy: string): string {
    const y = parseInt(yyyy) - 1;
    return `${y}`;
  }

  static next_yyyy(yyyy: string): string {
    const y = parseInt(yyyy) + 1;
    return `${y}`;
  }

  static added_yyyy(baseYyyy: string, yearsToAdd: number): string {
    const base = parseInt(baseYyyy);
    if (isNaN(base)) {
      null;
    }
    return `${base + yearsToAdd}`;
  }

  static yyyys_of_range(fromYyyy: string, toYyyy: string): string[] {
    let yyyys = [];
    const from = parseInt(fromYyyy);
    const to = parseInt(toYyyy);
    if (isNaN(from) || isNaN(to)) {
      return [];
    }
    if (from > to) {
      return [];
    }
    for (let i = from; i <= to; i++) {
      yyyys.push(`${i}`);
    }
    return yyyys;
  }

  // PUBNAVIで選択できる基本的なyyyyリスト
  // 3年前〜来年で仮決め
  static basicYyyyList(): string[] {
    const thisYyyy = this.yyyy();
    return this.yyyys_of_range('2011', this.next_yyyy(thisYyyy));
  }

  static last_date(yyyy: string, mm: string): number {
    const date = new Date(Number(yyyy), Number(mm), 0);
    return date.getDate();
  }

  static incrementMonth(current: number, add: number): number {
    const curInt = parseInt(current + '');
    const addInt = parseInt(add + '');
    if (curInt < 1 || curInt > 12) {
      return 0;
    }
    if (curInt + addInt > 12) {
      return this.incrementMonth(curInt, addInt - 12);
    } else if (curInt + addInt < 1) {
      return this.incrementMonth(curInt, addInt + 12);
    } else {
      return curInt + addInt;
    }
  }

  static get MaxDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return;
      }
      if (
        new Date(control.value).valueOf() > new Date('9999/12/31').valueOf()
      ) {
        return { max: true };
      }
      return null;
    };
  }
}
