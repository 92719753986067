import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuGroup, MenusService } from './../../../services/menus.service';

interface DisplayData {
  expanded: boolean;
  menuGroup: MenuGroup;
}

@Component({
  selector: 'menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss']
})
export class MenusComponent implements OnInit, OnDestroy {

  data: DisplayData[];

  constructor(
    private route: ActivatedRoute,
    private menus: MenusService
  ) { }

  ngOnInit() {
    const menuPath = this.route.snapshot.firstChild.url.map(u => u.path).join();

    this.menus.getAvailables().subscribe({
      next: menuGroups => {
        this.data = menuGroups.map(mg => {
          return { expanded: mg.pubnaviModule.path === menuPath, menuGroup: mg };
        });
      }
    });
  }

  ngOnDestroy() {
  }

}
