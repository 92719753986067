import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response, PartialList } from './models';

export interface News {
  id: string;
  title: string;
  body: string;
  url: string;
  display_yyyymmdd: string;
  open_at: Date;
  close_at: Date;
  open_flg: boolean;
}

export interface NewsFilter {
  limit: number;
  offset: number;
}

@Injectable()
export class NewsService {

  constructor(
    private http: HttpClient,
  ) { }

  listByFilter(d: NewsFilter): Observable<Response<PartialList<News>>> {
    let params = new HttpParams()
    .append('limit', String(d.limit))
    .append('offset', String(d.offset));
    return this.http.get<Response<PartialList<News>>>(`/authorized/news`, { params });
  }
}
