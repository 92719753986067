import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Response } from '../models';
import { ListFilter, PartialList, StdModel } from './../models';
import { CALC_TARGET_TYPES } from './ebook-royalty-contracts.service';
import { EbookRoyaltyCalcCanExecResponse } from './ebook-schedules.service';

export interface EbookRoyalty extends StdModel {
  sales_yyyymm: string;
  payee_code: string;
  payee_name: string;
  ebook_product_code: string;
  ebook_product_name: string;
  calc_target_type: string;
  calc_target_type_text: string;
  fixed_royalty_rate: number;
  fixed_royalty_rate_str: string;
}

export interface EbookRoyaltyForView extends StdModel {
  ebook_royalty: EbookRoyalty;
  current_retail_price: number;
  total_download_cnt: number;
  breakdowns_cnt: number;
}

export interface EbookRoyaltyFilter extends ListFilter {
  royalty_schedule_id: string;
}

export interface EbookRoyaltyBreakdown {
  royalty_id: string;
  row_num: number;
  sales_route_code: string;
  sales_route_name: string;
  store_name: string;
  store_sales_yyyymm: string;
  base_amount: number;
  fixed_royalty_rate: number;
  calc_target_type: string;
  tax_excluded_royalty: number;
  download_cnt: number;

  calc_target_type_text: string;
}

export interface EbookRoyaltyNotification extends StdModel {
  payee_code: string;
  payee_name: string;
  status: string;
  tax_excluded_royalty_total: number;
  consumption_tax_total: number;
  tax_included_royalty_total: number;
  notification_type: number;
  notified_at: Date;
  name_of_notified_by: string;
  downloaded_at: Date;
  name_of_downloaded_by: string;
  error_reason: string;
}

export interface EbookRoyaltyNotificationSendProcessArgs {
  royalty_schedule_id: string;
  send_option: string;
}

export interface EbookRoyaltyNotificationSingleArgs {
  royalty_notification_id: string;
}

export interface EbookRoyaltyNotificationFilter extends ListFilter {
  royalty_schedule_id: string;
  notifycation_type: number;
}

@Injectable()
export class EbookRoyaltyService {
  constructor(private http: HttpClient) {}

  postProcess(
    royalty_schedule_id: string
  ): Observable<Response<EbookRoyaltyCalcCanExecResponse>> {
    return this.http.post<Response<EbookRoyaltyCalcCanExecResponse>>(
      `/authorized/ebook-royalties/process`,
      { royalty_schedule_id }
    );
  }

  postProcessCancel(royalty_schedule_id: string): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `/authorized/ebook-royalties/process/cancel`,
      { royalty_schedule_id }
    );
  }

  listByFilter(
    d: EbookRoyaltyFilter
  ): Observable<Response<PartialList<EbookRoyaltyForView>>> {
    let params = new HttpParams()
      .append('royalty_schedule_id', d.royalty_schedule_id)
      .append('limit', String(d.limit))
      .append('offset', String(d.offset));
    return this.http
      .get<Response<PartialList<EbookRoyaltyForView>>>(
        `/authorized/ebook-royalties/list`,
        { params }
      )
      .pipe(tap((resp) => this.formatResponse(resp.data)));
  }

  private formatResponse(resp: PartialList<EbookRoyaltyForView>) {
    if (!resp || !resp.list) {
      return;
    }
    resp.list.forEach((e) => {
      e.ebook_royalty.calc_target_type_text = CALC_TARGET_TYPES[
        e.ebook_royalty.calc_target_type
      ]
        ? CALC_TARGET_TYPES[e.ebook_royalty.calc_target_type].text
        : e.ebook_royalty.calc_target_type;

      // 販路別印税率の場合は印税率をハイフンで表示
      if (e.ebook_royalty.fixed_royalty_rate == 0 && e.breakdowns_cnt > 1) {
        e.ebook_royalty.fixed_royalty_rate_str = '-';
      } else {
        e.ebook_royalty.fixed_royalty_rate_str =
          e.ebook_royalty.fixed_royalty_rate.toString();
      }
    });
  }

  listBreakdowns(id: string): Observable<Response<EbookRoyaltyBreakdown[]>> {
    let params = new HttpParams().append('royalty_id', id);
    return this.http
      .get<Response<EbookRoyaltyBreakdown[]>>(
        `/authorized/ebook-royalties/breakdowns`,
        { params }
      )
      .pipe(tap((resp) => this.formatBrkResponse(resp)));
  }

  formatBrkResponse(resp: Response<EbookRoyaltyBreakdown[]>) {
    if (!resp || !resp.data) {
      return;
    }
    resp.data.forEach((e) => {
      e.calc_target_type_text = CALC_TARGET_TYPES[e.calc_target_type]
        ? CALC_TARGET_TYPES[e.calc_target_type].text
        : e.calc_target_type;
    });
  }

  listIssues(id: string): Observable<Response<any[]>> {
    return this.http.get<Response<any[]>>(
      `/authorized/ebook-royalties/issues/${id}`
    );
  }

  listNotificationsByFilter(
    d: EbookRoyaltyNotificationFilter
  ): Observable<Response<PartialList<EbookRoyaltyNotification>>> {
    let params = new HttpParams()
      .append('royalty_schedule_id', d.royalty_schedule_id)
      .append('notification_type', String(d.notifycation_type))
      .append('limit', String(d.limit))
      .append('offset', String(d.offset));
    return this.http.get<Response<PartialList<EbookRoyaltyNotification>>>(
      `/authorized/ebook-royalties/notifications/list`,
      { params }
    );
  }

  putNotificationSendProcess(
    args: EbookRoyaltyNotificationSendProcessArgs
  ): Observable<Response<string>> {
    return this.http.post<Response<string>>(
      `/authorized/ebook-royalties/notifications/send/process`,
      args
    );
  }

  postSingleNotify(
    args: EbookRoyaltyNotificationSingleArgs
  ): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `/authorized/ebook-royalties/notifications/send-single`,
      args
    );
  }

  getNotificationDownloadURL(
    notification_id: string
  ): Observable<Response<any>> {
    let params = new HttpParams().append('id', notification_id);
    return this.http.get<Response<any>>(
      `/authorized/ebook-royalties/notifications/download-url`,
      { params }
    );
  }

  bulkGetNotificationDownloadURL(
    schedule_id: string,
    notification_type: string
  ): Observable<Response<any>> {
    return this.http.get<Response<any>>(
      `/authorized/ebook-royalties/notifications/bulk-download/${schedule_id}`,
      { params: { notification_type } }
    );
  }

  postFileExportProcess(d: EbookRoyaltyFilter): Observable<Response<string>> {
    return this.http.post<Response<string>>(
      `/authorized/ebook-royalties/export/process`,
      d
    );
  }

  getFileDownloadURL(schedule_id: string): Observable<Response<string>> {
    return this.http.get<Response<string>>(
      `/authorized/ebook-royalties/export/download-url/${schedule_id}`
    );
  }
}
