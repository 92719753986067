import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService, LoginResult } from './../../auth.service';


@Component({
  selector: 'user-password-form',
  templateUrl: './user-password-form.html',
  styleUrls: ['./form.scss']
})
export class UserPasswordForm implements OnInit {

  @Output() next = new EventEmitter<string>();

  form: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required]),
  });

  passwordVisible: boolean;

  processing: boolean;
  result: LoginResult;

  constructor(
    private auth: AuthService,
  ) { }

  get email() { return this.form.get('email'); }
  get password() { return this.form.get('password'); }

  ngOnInit() { }

  onPasswordForgotClick() {
    this.next.emit('PasswordForgotForm');
  }

  onSubmit() {
    Object.keys(this.form.controls).forEach(k => this.form.controls[k].markAsTouched());
    if (this.form.invalid) {
      return;
    }

    this.processing = true;
    this.result = null;

    this.auth
      .login(this.form.controls.email.value, this.form.controls.password.value)
      .subscribe({
        next: (res: LoginResult) => {
          this.result = res;
          switch (res) {
            case LoginResult.Succeeded:
              this.next.emit(null);
              break;
            case LoginResult.NewPasswordRequired:
              this.next.emit('NewPasswordForm');
              break;
            case LoginResult.NotAuthorized:
              this.password.setErrors({ invalid: true });
              break;
            case LoginResult.SupportUserLogin:
              this.next.emit('SupportUserLogin');
              break;
            default:
              break;
          }
        },
        error: _ => {
          this.result = LoginResult.Failed;
          this.processing = false;
        },
        complete: () => {
          this.processing = false;
        }
      });
  }
}