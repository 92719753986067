import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArrayUtils } from './../../utils/array.utils';
import { HttpParamEncoderUtil } from '../../utils/http-param-encoder.utils';
import { ListFilter, PartialList, Response } from './../models';

export interface EbookProductCodeMapping {
  sales_route_code?: string;
  sales_route_product_key?: string;
  optional_sales_route_product_key?: string;
  ebook_product_code?: string;
  created_at?: Date;
  created_by?: string;
  updated_at?: string;
  updated_by?: string;

  sales_route_name?: string;
  ebook_product_name?: string;
}

export interface EbookProductCodeKeySet {
  sales_route_code?: string;
  sales_route_product_key?: string;
  optional_sales_route_product_key?: string;
}

export interface EbookProductCodeMappingFilter extends ListFilter {
  sales_route_code?: string;
  sales_route_name?: string;
  sales_route_product_key?: string;
  optional_sales_route_product_key?: string;
  ebook_product_code?: string;
  ebook_product_name?: string;
}

@Injectable()
export class EbookProductCodeMappingsService {

  constructor(
    private http: HttpClient,
  ) { }

  emptyType(): EbookProductCodeMapping {
    return {
      sales_route_code: null,
      sales_route_product_key: null,
      ebook_product_code: null
    };
  }

  listByFilter(d: EbookProductCodeMappingFilter): Observable<Response<PartialList<EbookProductCodeMapping>>> {
    let params = new HttpParams()
      .append('limit', String(d.limit))
      .append('offset', String(d.offset));
    d.sales_route_code && (params = params.append('sales_route_code', d.sales_route_code));
    d.sales_route_product_key && (params = params.append('sales_route_product_key', d.sales_route_product_key));
    d.optional_sales_route_product_key && (params = params.append('optional_sales_route_product_key', d.optional_sales_route_product_key));
    d.ebook_product_code && (params = params.append('ebook_product_code', d.ebook_product_code));
    return this.http.get<Response<PartialList<EbookProductCodeMapping>>>(`/authorized/ebook-product-code-mappings`, { params });
  }

  listByKeys(d: EbookProductCodeKeySet[]): Observable<Response<EbookProductCodeMapping[]>> {
    return forkJoin(
      ArrayUtils.toChunks(d).map(codes => this.http.post<Response<EbookProductCodeMapping[]>>(`/authorized/ebook-product-code-mappings/keys`, codes))
    ).pipe(
      map(respArr => {
        if (respArr.some(e => !e.success)) {
          return { success: false, data: null };
        }

        const merged = [];
        respArr.forEach(e => {
          merged.push(...e.data);
        });
        return { success: true, data: merged };
      })
    );
  }

  post(d: EbookProductCodeMapping): Observable<Response<any>> {
    let mappings: EbookProductCodeMapping[] = new Array();
    mappings.push(d);
    return this.http.post<Response<any>>(`/authorized/ebook-product-code-mappings`, mappings);
  }

  put(d: EbookProductCodeMapping): Observable<Response<any>> {
    let mappings: EbookProductCodeMapping[] = new Array();
    mappings.push(d);
    return this.http.put<Response<any>>(`/authorized/ebook-product-code-mappings`, mappings);
  }

  postBulk(d: EbookProductCodeMapping[]): Observable<Response<boolean>> {
    return forkJoin(
      ArrayUtils.toChunks(d).map(mappings => this.http.post<Response<boolean>>(`/authorized/ebook-product-code-mappings`, mappings))
    ).pipe(
      map(respArr => {
        if (respArr.some(e => !e.success)) {
          return { success: false, data: null };
        }
        return { success: true, data: null }
      })
    );
  }

  delete(d: EbookProductCodeMapping): Observable<Response<any>> {
    let params = new HttpParams({ encoder: new HttpParamEncoderUtil() })
    d.sales_route_code && (params = params.append('sales_route_code', d.sales_route_code));
    d.sales_route_product_key && (params = params.append('sales_route_product_key', d.sales_route_product_key));
    d.optional_sales_route_product_key && (params = params.append('optional_sales_route_product_key', d.optional_sales_route_product_key));
    return this.http.delete<Response<any>>(`/authorized/ebook-product-code-mappings`, { params });
  }
}
