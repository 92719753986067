<div class="authenticator">

  <div class="form-area">

    <div class="form-container mat-elevation-z2" [ngSwitch]="activeFormName">
      <div class="intro">
        <img src="assets/logo.png">
        <span *ngSwitchCase="'UserPasswordForm'">サービスにログイン</span>
        <span *ngSwitchCase="'NewPasswordForm'">新しいパスワードを設定</span>
        <span *ngSwitchCase="'PasswordForgotForm'">パスワードを忘れた場合</span>
        <span *ngSwitchCase="'PasswordResetForm'">パスワードを再設定</span>
        <span *ngSwitchCase="'SupportUserLogin'">サポートユーザーでログイン中</span>
      </div>

      <mat-divider></mat-divider>

      <user-password-form *ngSwitchCase="'UserPasswordForm'" (next)="onNextForm($event)"></user-password-form>
      <new-password-form *ngSwitchCase="'NewPasswordForm'" (next)="onNextForm($event)"></new-password-form>
      <password-forgot-form *ngSwitchCase="'PasswordForgotForm'" (next)="onNextForm($event)"></password-forgot-form>
      <password-reset-form *ngSwitchCase="'PasswordResetForm'" (next)="onNextForm($event)"></password-reset-form>
      <support-user-form *ngSwitchCase="'SupportUserLogin'" (next)="onNextForm($event)"></support-user-form>
    </div>

  </div>

  <div class="footer">
  </div>

</div>