import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EbookMonthlyRoyaltySchedule } from '../ebook/ebook-schedules.service';
import {
  ListFilter,
  NOTIFICATION_TYPES,
  PAYMENT_METHODS,
  PartialList,
  Response,
  SimpleBusinessError,
  StdModel,
} from './../models';

export interface MonthlyPaymentSchedule extends StdModel {
  yyyymm?: string;
  status?: string;
  tax_excluded_amount?: number;
  consumption_tax?: number;
  tax_included_amount?: number;
  carryovered_tax_excluded_amount?: number;
  carryovered_consumption_tax?: number;
  carryovered_tax_included_amount?: number;
  withholding_tax?: number;
  fixed_payment_amount?: number;
  payment_due_mmdd?: string;
  invoked_at: Date;
  invoked_by?: string;
  // front表示用
  yyyymm_text?: string;
  payment_due_mmdd_text?: string;
  payment_due_dd?: number;
}

export interface PaymentConfirmInfo extends MonthlyPaymentSchedule {
  minus_amount_payment_count?: number;
  carryovered_payment_count?: number;
}

export interface PaymentBulkNotifyProcArgs {
  schedule_id?: string;
  send_option?: string;
}

export interface PaymentConfirmProcArgs {
  schedule_id?: string;
  form_issued_yyyymmdd?: string;
}

export interface PaymentSingleNotifyProcArgs {
  schedule_id?: string;
  payee_code?: string;
}
export interface EnqueueScheduledProcessResults {
  worker_id?: string;
  status?: string;
}

export interface Payment extends StdModel {
  aggregation_schedule_id?: string;
  payee_code?: string;
  payee_name?: string;
  payee_email?: string;
  payee_invoice_registration_number?: string;
  payee_payment_notification_type?: number;
  payee_payment_method?: string;
  tax_excluded_amount?: number;
  consumption_tax?: number;
  tax_included_amount?: number;
  carryovered_tax_excluded_amount?: number;
  carryovered_consumption_tax?: number;
  carryovered_tax_included_amount?: number;
  carryovered_reason?: { [key: string]: string }; //例: {"PROCESS_STOPPED":"口座凍結のため", "TENANT_THRESHOLD":"2000"}
  withholding_tax?: number;
  fixed_payment_amount?: number;
  notification_emailed_at?: Date;
  notification_emailed_by?: string;
  notification_downloaded_at?: Date;
  notification_downloaded_by?: string;
  carryovered_flag?: boolean;
  manual_manage_carryovered_flag?: boolean;
  void_payment_flag?: boolean;
  excludes_form?: boolean;

  tenant_notifies_payment_when_under_threshold?: boolean;

  payee_payment_notification_type_text?: string;
  payee_payment_method_text?: string;
}

export const PAYMENT_HANDLE_TYPES = {
  1: '支払',
  2: ' 支払保留',
  3: 'マイナス支払額',
  4: '支払無効',
};
export interface PaymentForDisplay extends Payment {
  status_text?: string;
  carryovered_reason_text?: string[];
  name_of_notification_emailed_by?: string;
  name_of_notification_downloaded_by?: string;
}

export interface PaymentFilter extends ListFilter {
  aggregation_schedule_id?: string;
  payee_payment_notification_type?: number;
  payment_handle_type?: number;
  ne_fixed_payment_amount?: number;
  excludes_no_form?: boolean;
}

export interface PaymentAggregationTarget {
  royalty_schedules?: EbookMonthlyRoyaltySchedule[];
  total_editing_cost?: number;
  total_prepayment?: number;
  total_carryover?: number;
  err_messages?: string[];
}

export interface PaymentConfirmTarget {
  monthly_payment_schedule: MonthlyPaymentSchedule;
  total_count?: number;
  total_payment_count?: number;
  total_carryovered_payment_count?: number;
  total_minus_amount_payment_count?: number;
  total_void_payment_count?: number;
}

export const PAYMENT_SCHEDULE_STATUS = {
  PENDING: 'PENDING',
  AGGREGATION_PROCESSING: 'AGGREGATION_PROCESSING',
  AGGREGATION_REVERTING: 'AGGREGATION_REVERTING',
  AGGREGATION_DONE: 'AGGREGATION_DONE',
  CONFIRMATION_PROCESSING: 'CONFIRMATION_PROCESSING',
  CONFIRMATION_REVERTING: 'CONFIRMATION_REVERTING',
};

@Injectable()
export class PaymentsService {
  constructor(private http: HttpClient) {}

  private formatPaymentForDisplayResponse(resp: PaymentForDisplay[]) {
    if (resp != null) {
      resp.forEach((e) => {
        e.notification_emailed_at &&
          (e.notification_emailed_at = new Date(e.notification_emailed_at));
        e.notification_downloaded_at &&
          (e.notification_downloaded_at = new Date(
            e.notification_downloaded_at
          ));
        e.payee_payment_notification_type_text =
          NOTIFICATION_TYPES[e.payee_payment_notification_type];
        e.payee_payment_method_text = PAYMENT_METHODS[e.payee_payment_method];
        e.payee_email = e.payee_email.replace(/,/g, '\n');
      });
    }
  }

  listMonthlyPaymentSchedules(
    year: string
  ): Observable<Response<MonthlyPaymentSchedule[]>> {
    return this.http.get<Response<MonthlyPaymentSchedule[]>>(
      `/authorized/payments/schedules`,
      { params: { year } }
    );
  }

  getMonthlyPaymentSchedules(
    schedule_id: string
  ): Observable<Response<MonthlyPaymentSchedule>> {
    return this.http.get<Response<MonthlyPaymentSchedule>>(
      `/authorized/payments/schedules/${schedule_id}`
    );
  }

  getPaymentAggregationTarget(
    schedule_id: string
  ): Observable<Response<PaymentAggregationTarget>> {
    return this.http.get<Response<PaymentAggregationTarget>>(
      `/authorized/payments/aggregation-target`,
      { params: { schedule_id } }
    );
  }

  getPaymentConfirmTarget(
    schedule_id: string
  ): Observable<Response<PaymentConfirmTarget>> {
    return this.http.get<Response<PaymentConfirmTarget>>(
      `/authorized/payments/confirm-target`,
      { params: { schedule_id } }
    );
  }

  patch(d: MonthlyPaymentSchedule): Observable<Response<SimpleBusinessError>> {
    return this.http.patch<Response<any>>(
      `/authorized/payments/schedule/${d.yyyymm}`,
      d
    );
  }

  listPaymentsByFilter(
    d: PaymentFilter
  ): Observable<Response<PartialList<PaymentForDisplay>>> {
    let params = new HttpParams()
      .append('limit', String(d.limit))
      .append('offset', String(d.offset));
    d.aggregation_schedule_id &&
      (params = params.append(
        'aggregation_schedule_id',
        d.aggregation_schedule_id
      ));
    params = params.append('excludes_no_form', d.excludes_no_form.toString());
    isFinite(d.payment_handle_type) &&
      (params = params.append(
        'payment_handle_type',
        String(d.payment_handle_type)
      ));
    isFinite(d.payee_payment_notification_type) &&
      (params = params.append(
        'payee_payment_notification_type',
        String(d.payee_payment_notification_type)
      ));
    isFinite(d.ne_fixed_payment_amount) &&
      (params = params.append(
        'ne:fixed_payment_amount',
        String(d.ne_fixed_payment_amount)
      ));
    return this.http
      .get<Response<PartialList<PaymentForDisplay>>>(
        `/authorized/payments/resources`,
        { params }
      )
      .pipe(tap((res) => this.formatPaymentForDisplayResponse(res.data.list)));
  }

  checkBulkDownloadable(
    schedule_id: string
  ): Observable<Response<SimpleBusinessError>> {
    return this.http.get<Response<SimpleBusinessError>>(
      `/authorized/payments/bulk-downloadable/${schedule_id}`
    );
  }

  holdPayments(payment_id: string): Observable<Response<any>> {
    return this.http.patch<Response<any>>(
      `/authorized/payments/hold/${payment_id}`,
      {}
    );
  }

  releaseHoldPayments(payment_id: string): Observable<Response<any>> {
    return this.http.patch<Response<any>>(
      `/authorized/payments/release-hold/${payment_id}`,
      {}
    );
  }

  voidPayments(payment_id: string): Observable<Response<any>> {
    return this.http.patch<Response<any>>(
      `/authorized/payments/void/${payment_id}`,
      {}
    );
  }

  cancelVoidPayments(payment_id: string): Observable<Response<any>> {
    return this.http.patch<Response<any>>(
      `/authorized/payments/cancel-void/${payment_id}`,
      {}
    );
  }

  postAggregate(
    schedule_id: string
  ): Observable<Response<EnqueueScheduledProcessResults>> {
    return this.http.post<Response<EnqueueScheduledProcessResults>>(
      `/authorized/payments/aggregate`,
      { schedule_id }
    );
  }

  postAggregateRevert(
    schedule_id: string
  ): Observable<
    Response<EnqueueScheduledProcessResults | SimpleBusinessError>
  > {
    return this.http.post<
      Response<EnqueueScheduledProcessResults | SimpleBusinessError>
    >(`/authorized/payments/aggregate/revert`, { schedule_id });
  }

  postConfirm(
    args: PaymentConfirmProcArgs
  ): Observable<Response<EnqueueScheduledProcessResults>> {
    return this.http.post<Response<EnqueueScheduledProcessResults>>(
      `/authorized/payments/confirm`,
      args
    );
  }

  postConfirmRevert(
    schedule_id: string
  ): Observable<
    Response<EnqueueScheduledProcessResults | SimpleBusinessError>
  > {
    return this.http.post<
      Response<EnqueueScheduledProcessResults | SimpleBusinessError>
    >(`/authorized/payments/confirm/revert`, { schedule_id });
  }

  postBulkNotify(
    args: PaymentBulkNotifyProcArgs
  ): Observable<Response<EnqueueScheduledProcessResults>> {
    return this.http.post<Response<EnqueueScheduledProcessResults>>(
      `/authorized/payments/bulk-notify`,
      args
    );
  }

  postSingleNotify(
    args: PaymentSingleNotifyProcArgs
  ): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `/authorized/payments/single-notify`,
      args
    );
  }

  postNotifyRevert(
    schedule_id: string
  ): Observable<
    Response<EnqueueScheduledProcessResults | SimpleBusinessError>
  > {
    return this.http.post<
      Response<EnqueueScheduledProcessResults | SimpleBusinessError>
    >(`/authorized/payments/notify/revert`, { schedule_id });
  }

  postNotifyDone(
    schedule_id: string
  ): Observable<Response<EnqueueScheduledProcessResults>> {
    return this.http.post<Response<EnqueueScheduledProcessResults>>(
      `/authorized/payments/notify/done`,
      { schedule_id }
    );
  }

  getNotifyDownloadURL(
    schedule_id: string,
    payee_code: string
  ): Observable<Response<any>> {
    return this.http.get<Response<any>>(
      `/authorized/payments/notify/download-url/${schedule_id}/${payee_code}`
    );
  }

  bulkGetNotifyDownloadURL(
    schedule_id: string,
    payee_payment_notification_type: string
  ): Observable<Response<any>> {
    return this.http.get<Response<any>>(
      `/authorized/payments/notify/bulk-download/${schedule_id}`,
      { params: { payee_payment_notification_type } }
    );
  }

  postFbRevert(
    schedule_id: string
  ): Observable<
    Response<EnqueueScheduledProcessResults | SimpleBusinessError>
  > {
    return this.http.post<
      Response<EnqueueScheduledProcessResults | SimpleBusinessError>
    >(`/authorized/payments/execute/revert`, { schedule_id });
  }

  postFbDone(
    schedule_id: string
  ): Observable<Response<EnqueueScheduledProcessResults>> {
    return this.http.post<Response<EnqueueScheduledProcessResults>>(
      `/authorized/payments/execute/done`,
      { schedule_id }
    );
  }

  getFbDownloadURL(schedule_id: string): Observable<Response<string>> {
    return this.http
      .get<Response<any>>(
        `/authorized/payments/execute/download-url/${schedule_id}`
      )
      .pipe(
        tap((res) => {
          res.data = res.data.url;
        })
      );
  }

  processExcelExport(schedule_id: string): Observable<Response<string>> {
    return this.http.post<Response<string>>(
      `/authorized/payments/excel/export/process`,
      { schedule_id }
    );
  }

  getExcelDownloadURL(yyyymm: string): Observable<Response<string>> {
    return this.http.get<Response<string>>(
      `/authorized/payments/excel/download-url/${yyyymm}`
    );
  }
}
