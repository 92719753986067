import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { int } from 'aws-sdk/clients/datapipeline';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  FailedResponseData,
  FileUploadParams,
  Response,
  SimpleBusinessError,
} from '../models';
import {
  ExcelUploadRequest,
  SettingImportStatusBase,
  TemporaryImportedMaster,
} from '../setting-import/setting-import.service';
import { DEFAULT_CODE, ListFilter, PartialList, StdModel } from './../models';

/** 計算方法 */
export const CALC_METHOD_TYPES = {
  RATE: { value: 'RATE', text: '印税率と配分率' },
  RATE_BY_PAYEE: { value: 'RATE_BY_PAYEE', text: '支払先別印税率' },
};

/** 計算対象とする項目 */
export const CALC_TARGET_TYPES = {
  // METHOD = RATE or RATE_BY_PAYEE
  DEPOSIT_TAXEXC: { value: 'DEPOSIT_TAXEXC', text: '入金額' },
  SALES_TAXEXC: { value: 'SALES_TAXEXC', text: '売上額' },
  PROD_RETAIL_PRICE: {
    value: 'PROD_RETAIL_PRICE',
    text: '商品マスタの希望小売価格',
  },
};

export interface EbookRoyaltyContractRoot extends StdModel {
  name?: string;
  contract_number?: string;
  ebook_product_code?: string;
  ebook_product_name?: string;
  payee_codes_if_default?: string;
  product_title_name?: string;
  read_unlimited_flg?: number | boolean;
  memo?: string;
  imported_worker_id?: string;
  invalid_flg?: number;

  contract_calc_settings?: EbookRoyaltyContractCalcSetting[];
}

export interface EbookRoyaltyContractCalcSetting extends StdModel {
  end_date?: Date;
  contract_id?: string;
  sales_route_code?: string;
  sales_route_name?: string;
  store_name?: string;
  calc_target_type?: string;
  calc_method_type?: string;
  royalty_rate?: number;
  contract_name?: string;

  contract_payees?: EbookRoyaltyContractPayee[];

  calc_target_type_text?: string;
  calc_method_type_text?: string;
  end_date_before_change?: Date;
  start_date?: Date;
}

export function isDefault(cs: EbookRoyaltyContractCalcSetting): boolean {
  return cs.sales_route_code === DEFAULT_CODE && cs.store_name === DEFAULT_CODE;
}

export interface EbookRoyaltyContractPayee extends StdModel {
  end_date?: Date;
  contract_id?: string;
  payee_code?: string;
  payee_name?: string;
  allocation_rate?: number;
  accounting_code?: string;
  accounting_name?: string;
  contract_name?: string;
  ebook_product_code?: string;
  read_unlimited_flg?: number | boolean;

  end_date_before_change?: Date;
}

export interface EbookRoyaltyContractFilter extends ListFilter {
  is_valid_only?: boolean;
  contract_number_from?: string;
  contract_number_to?: string;
  name_like?: string;
  ebook_product_code?: string;
  ebook_product_name?: string;
  payee_code?: string;
  payee_name?: string;
  contract_type?: EbookRoyaltyContractType | '';
  single_or_multi?: string;
  last_imported?: boolean;
}

export interface ListTempImportedContractsResponse {
  has_error?: boolean;
  error_msg?: string;
  masters?: TemporaryImportedMaster<EbRoyContRootRow | EbRoyContCalcSetRow>[];
  num_of_total?: number;
}

export interface EbRoyContRootRow {
  excel_row_num: int;
  row_num: string;
  contract_number: string;
  contract_name?: string;
  ebook_product_code?: string;
  ebook_product_name?: string;
  product_title_name?: string;
  read_unlimited_flg: boolean | number;
  memo?: string;
  calc_method_type?: string;
  calc_target_type?: string;
  royalty_rate?: number;
  accounting_code?: string;
  accounting_name?: string;
}

export interface EbRoyContCalcSetRow {
  excel_row_num: int;
  row_num: string;
  contract_number: string;
  read_unlimited_flg: boolean | number;
  sales_route_code?: string;
  sales_route_name?: string;
  store_name?: string;
  start_date?: Date;
  end_date?: Date;
  calc_method_type?: string;
  calc_target_type?: string;
  royalty_rate?: number;
  payee_code: string;
  payee_name: string;
  accounting_code?: string;
  accounting_name?: string;
}

export type EbookRoyaltyContractType =
  | 'EbookProduct'
  | 'EbookProductTitle'
  | 'Payees'
  | 'Tenant';
export type EbookRoyaltyContractCalcSettingType =
  | 'Store'
  | 'SalesRoute'
  | 'Default';

@Injectable()
export class EbookRoyaltyContractsService {
  constructor(private http: HttpClient) {}

  initRoot(): EbookRoyaltyContractRoot {
    return {
      name: '',
      contract_number: '',
      ebook_product_code: '',
      ebook_product_name: '',
      payee_codes_if_default: '',
      product_title_name: '',
      read_unlimited_flg: 0,
      memo: '',
      imported_worker_id: '',
      invalid_flg: 0,

      contract_calc_settings: [],
    };
  }

  initCalcSetting(): EbookRoyaltyContractCalcSetting {
    return {
      end_date: new Date(9999, 11, 31),
      contract_id: '',
      sales_route_code: '',
      sales_route_name: '',
      store_name: '',
      calc_target_type: 'DEPOSIT_TAXEXC',
      calc_method_type: 'RATE',
      royalty_rate: 0,
      contract_name: '',

      contract_payees: [],

      calc_target_type_text: CALC_TARGET_TYPES.DEPOSIT_TAXEXC.text,
      calc_method_type_text: CALC_METHOD_TYPES.RATE.text,
      end_date_before_change: null,
    };
  }

  getContractTypeOf(r: EbookRoyaltyContractRoot): EbookRoyaltyContractType {
    if (
      r.ebook_product_code === DEFAULT_CODE &&
      r.product_title_name === DEFAULT_CODE &&
      r.payee_codes_if_default === DEFAULT_CODE
    ) {
      return 'Tenant';
    }
    if (
      r.payee_codes_if_default &&
      r.ebook_product_code === DEFAULT_CODE &&
      r.product_title_name === DEFAULT_CODE
    ) {
      return 'Payees';
    }
    if (r.product_title_name && r.product_title_name !== DEFAULT_CODE) {
      return 'EbookProductTitle';
    }
    if (r.ebook_product_code && r.ebook_product_code !== DEFAULT_CODE) {
      return 'EbookProduct';
    }

    return 'EbookProduct';
  }

  getCalcSettingTypeOf(
    cs: EbookRoyaltyContractCalcSetting
  ): EbookRoyaltyContractCalcSettingType {
    if (
      cs.sales_route_code === DEFAULT_CODE &&
      cs.store_name === DEFAULT_CODE
    ) {
      return 'Default';
    }
    if (
      cs.sales_route_code &&
      cs.sales_route_code !== DEFAULT_CODE &&
      cs.store_name === DEFAULT_CODE
    ) {
      return 'SalesRoute';
    }
    if (
      cs.sales_route_code &&
      cs.sales_route_code !== DEFAULT_CODE &&
      cs.store_name &&
      cs.store_name !== DEFAULT_CODE
    ) {
      return 'Store';
    }

    return 'Default';
  }

  private formatResponse(resp: EbookRoyaltyContractRoot[]) {
    if (!resp) {
      return;
    }
    resp.forEach((r) => {
      r.read_unlimited_flg = r.read_unlimited_flg === true ? 1 : 0;
      r.contract_calc_settings.forEach((cs) => {
        cs.end_date && (cs.end_date = new Date(cs.end_date));
        cs.calc_target_type_text = CALC_TARGET_TYPES[cs.calc_target_type]
          ? CALC_TARGET_TYPES[cs.calc_target_type].text
          : cs.calc_target_type;
        cs.calc_method_type_text = CALC_METHOD_TYPES[cs.calc_method_type]
          ? CALC_METHOD_TYPES[cs.calc_method_type].text
          : cs.calc_method_type;
      });
    });
  }

  listByFilter(
    d: EbookRoyaltyContractFilter
  ): Observable<Response<PartialList<EbookRoyaltyContractRoot>>> {
    let params = new HttpParams()
      .append('limit', String(d.limit))
      .append('offset', String(d.offset))
      .append('is_valid_only', String(d.is_valid_only))
      .append('last_imported', String(d.last_imported));

    d.ebook_product_code &&
      (params = params.append('ebook_product_code', d.ebook_product_code));
    d.payee_code && (params = params.append('payee_code', d.payee_code));
    d.contract_number_from &&
      (params = params.append('contract_number_from', d.contract_number_from));
    d.contract_number_to &&
      (params = params.append('contract_number_to', d.contract_number_to));
    d.name_like && (params = params.append('name_like', d.name_like));
    d.contract_type &&
      (params = params.append('contract_type', d.contract_type));
    d.single_or_multi &&
      (params = params.append('single_or_multi', d.single_or_multi));

    return this.http
      .get<Response<PartialList<EbookRoyaltyContractRoot>>>(
        `/authorized/ebook-contracts`,
        { params }
      )
      .pipe(tap((res) => this.formatResponse(res.data.list)));
  }

  post(d: EbookRoyaltyContractRoot): Observable<Response<FailedResponseData>> {
    return this.http.post<Response<FailedResponseData>>(
      `/authorized/ebook-contracts`,
      d
    );
  }

  split(
    d: EbookRoyaltyContractCalcSetting
  ): Observable<Response<FailedResponseData | SimpleBusinessError>> {
    return this.http.post<Response<FailedResponseData>>(
      `/authorized/ebook-contracts/calc-setting/split`,
      d
    );
  }

  delete(
    d: EbookRoyaltyContractRoot
  ): Observable<Response<SimpleBusinessError>> {
    return this.http.delete<Response<any>>(
      `/authorized/ebook-contracts/root/${d.id}`
    );
  }

  deleteCalcSetting(
    cs: EbookRoyaltyContractCalcSetting
  ): Observable<Response<SimpleBusinessError>> {
    const endDateStr =
      cs.end_date.getFullYear() +
      '-' +
      (cs.end_date.getMonth() + 1) +
      '-' +
      cs.end_date.getDate();
    return this.http.delete<Response<any>>(
      `/authorized/ebook-contracts/calc-setting/${cs.contract_id}/${cs.id}/${endDateStr}`
    );
  }

  getExcelUploadURL(
    val: ExcelUploadRequest
  ): Observable<Response<FileUploadParams>> {
    return this.http.post<Response<FileUploadParams>>(
      `/authorized/ebook-contracts/excel/upload-url`,
      val
    );
  }

  processExcelImport(val: ExcelUploadRequest): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `/authorized/ebook-contracts/excel/import/process`,
      val
    );
  }

  getImportStatus(): Observable<Response<SettingImportStatusBase>> {
    return this.http.get<Response<SettingImportStatusBase>>(
      `/authorized/ebook-contracts/excel/import/status`
    );
  }

  listTempImportedMasters(
    target: string,
    condition: string,
    limit: number,
    offset: number
  ): Observable<Response<ListTempImportedContractsResponse>> {
    let params = new HttpParams()
      .append('target', target)
      .append('condition', condition)
      .append('limit', String(limit))
      .append('offset', String(offset));
    return this.http
      .get<Response<ListTempImportedContractsResponse>>(
        `/authorized/ebook-contracts/temp-imported`,
        { params }
      )
      .pipe(
        tap((d) => {
          d.data.masters.forEach((m) => {
            // HACK EbRoyContRootRowは、readUnlimitedFlg以外にフォーマットする必要がないので
            this.formatRowResponse(m.data as EbRoyContCalcSetRow, target);
            if (m.comparison) {
              this.formatRowResponse(
                m.comparison as EbRoyContCalcSetRow,
                target
              );
            }
          });
        })
      );
  }

  private formatRowResponse(data: EbRoyContCalcSetRow, target: string) {
    data.read_unlimited_flg = data.read_unlimited_flg === true ? 1 : 0;
    if (target === 'calcSet') {
      data = data as EbRoyContCalcSetRow;
      data.start_date && (data.start_date = new Date(data.start_date));
      data.end_date && (data.end_date = new Date(data.end_date));
    }
    // MEMO calc_method_type/calc_target_typeのtext置換は画面側でやる
  }

  processExcelImportFix(): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `/authorized/ebook-contracts/excel/import/fix`,
      null
    );
  }

  processExcelExport(
    d: EbookRoyaltyContractFilter
  ): Observable<Response<string>> {
    return this.http.post<Response<string>>(
      `/authorized/ebook-contracts/excel/export/process`,
      d
    );
  }

  getExcelDownloadURL(): Observable<Response<string>> {
    return this.http.get<Response<string>>(
      `/authorized/ebook-contracts/excel/download-url`
    );
  }
}
