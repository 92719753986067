import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService, PaswordResetResult } from './../../auth.service';


@Component({
  selector: 'password-reset-form',
  templateUrl: './password-reset-form.html',
  styleUrls: ['./form.scss']
})
export class PasswordResetForm implements OnInit {

  @Output() next = new EventEmitter<string>();

  form: UntypedFormGroup = new UntypedFormGroup({
    code: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required, , AuthService.passwordValidator]),
  });

  passwordVisible: boolean;

  processing: boolean;
  result: PaswordResetResult;

  constructor(
    private auth: AuthService,
  ) { }

  get code() { return this.form.get('code'); }
  get email() { return this.form.get('email'); }
  get password() { return this.form.get('password'); }

  ngOnInit() { }

  onGoToLoginClick() {
    this.next.emit('UserPasswordForm');
  }

  onSubmit() {
    Object.keys(this.form.controls).forEach(k => this.form.controls[k].markAsTouched());
    if (this.form.invalid) {
      return;
    }

    this.processing = true;
    this.result = null;

    this.auth
      .resetPassword(this.form.controls.code.value, this.form.controls.email.value, this.form.controls.password.value)
      .subscribe({
        next: (res: PaswordResetResult) => {
          this.result = res;
          switch (res) {
            case PaswordResetResult.CodeMissmatch:
              this.code.setErrors({ invalid: true });
              break;
            case PaswordResetResult.CodeExpired:
              this.code.setErrors({ expired: true });
              break;
            case PaswordResetResult.UserNotFound:
              this.email.setErrors({ invalid: true });
              break;
            default:
              break;
          }
        },
        error: _ => {
          this.result = PaswordResetResult.Failed;
          this.processing = false;
        },
        complete: () => {
          this.processing = false;
        }
      });
  }
}