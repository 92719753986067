import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from './../../auth.service';


@Component({
  selector: 'new-password-form',
  templateUrl: './new-password-form.html',
  styleUrls: ['./form.scss']
})
export class NewPasswordForm implements OnInit {

  @Output() next = new EventEmitter<String>();

  form: UntypedFormGroup = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.required, AuthService.passwordValidator]),
  });

  passwordVisible: boolean;

  processing: boolean;
  failed: boolean;

  constructor(
    private auth: AuthService,
  ) { }

  get password() { return this.form.get('password'); }

  ngOnInit() { }

  onSubmit() {
    Object.keys(this.form.controls).forEach(k => this.form.controls[k].markAsTouched());
    if (this.form.invalid) {
      return;
    }

    this.processing = true;
    this.failed = false;

    this.auth
      .updateNewPassword(this.form.controls.password.value)
      .subscribe({
        next: res => {
          if (res === true) {
            this.next.emit(null);
          }
        },
        error: _ => {
          this.failed = true;
          this.processing = false;
        },
        complete: () => {
          this.processing = false;
        }
      });
  }
}