import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatModule } from './../mat.module';
import { ApplicationErrorComponent, ApplicationErrorHandler } from './application-error/application-error.component';
import { AuthHttpInterceptor } from './auth.http';
import { AuthService } from './auth.service';
import { AuthenticatorComponent } from './authenticator/authenticator.component';
import { NewPasswordForm } from './authenticator/_forms/new-password-form';
import { PasswordForgotForm } from './authenticator/_forms/password-forgot-form';
import { PasswordResetForm } from './authenticator/_forms/password-reset-form';
import { UserPasswordForm } from './authenticator/_forms/user-password-form';
import { SupportUserForm } from './authenticator/_forms/support-user-form';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatModule,
  ],
  declarations: [
    ApplicationErrorComponent,
    AuthenticatorComponent,
    UserPasswordForm,
    NewPasswordForm,
    PasswordForgotForm,
    PasswordResetForm,
    SupportUserForm,
  ],
  exports: [
    ApplicationErrorComponent,
    AuthenticatorComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ApplicationErrorHandler },
    AuthService,
  ],
})
export class AuthModule { }