import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Response } from './../models';

export interface SharedEbookSalesImportSetting {
  sales_route_code?: string;
  end_date?: string;
  pre_fix_type?: string;
  read_unlimited_flg?: number;
  reading_start_row?: number;
  store_name_col?: number;
  store_company_name_col?: number;
  default_store_name?: string;
  default_store_company_name?: string;
  sales_route_product_code_col?: number;
  ebook_product_link_code_col?: number;
  download_cnt_col?: number;
  pv_cnt_col?: number;
  deposit_rate_col?: number;
  unit_price_col?: number;
  unit_price_tax_type?: string;
  tax_excluded_sales_amount_col?: number;
  tax_included_sales_amount_col?: number;
  sales_tax_calc_type?: string;
  tax_excluded_deposit_amount_col?: number;
  tax_included_deposit_amount_col?: number;
  deposit_tax_calc_type?: string;
  data_exist_check_col?: number;
  currency_code_col?: number;
  sales_route_product_name_col?: number;
  optional_sales_route_product_name_col?: number;
  store_sales_yyyymm_col?: number;
  number_of_columns?: number;
  delimiter?: string;
  delimiter_text?: string;
  file_format?: string;
  file_format_text?: string;
}

// 販売単価 税込み/税抜き
export const UnitPriceTaxType = {
  TAX_INCLUDED: { value: 'TAX_INCLUDED', text: '税込み' },
  TAX_EXCLUDED: { value: 'TAX_EXCLUDED', text: '税抜き' },
};

// 売上金額消費税算出方法
export const SalesTaxCalcType = {
  TAXABLE: { value: 'TAXABLE', text: '課税' },
  NON_TAXABLE: { value: 'NON_TAXABLE', text: '非課税' },
};

// 入金額消費税算出方法
export const DepositTaxCalcType = {
  TAXABLE: { value: 'TAXABLE', text: '課税' },
  NON_TAXABLE: { value: 'NON_TAXABLE', text: '非課税' },
};

// デリミタ
export const Delimiter = {
  COMMA: { value: 'COMMA', text: 'カンマ' },
  TAB: { value: 'TAB', text: 'タブ' },
};

// ファイル形式
export const FileFormat = {
  CSV: { value: 'CSV', text: 'CSV' },
  TSV: { value: 'TSV', text: 'TSV' },
  EXCEL: { value: 'EXCEL', text: 'EXCEL' },
};

@Injectable()
export class SharedEbookSalesImportSettingsService {
  constructor(private http: HttpClient) {}

  private formatResponse(resp: SharedEbookSalesImportSetting[]) {
    resp.forEach((e) => {
      e.file_format_text = FileFormat[e.file_format].text;
    });
  }

  listBy(
    salesRouteCode: string
  ): Observable<Response<SharedEbookSalesImportSetting[]>> {
    return this.http
      .get<Response<SharedEbookSalesImportSetting[]>>(
        `/authorized/sales-import-settings/shared/${salesRouteCode}`
      )
      .pipe(
        tap((res) => {
          if (res.data) {
            this.formatResponse(res.data);
          }
        })
      );
  }
}
