<mat-drawer-container [hasBackdrop]="true">
  <mat-drawer #drawer [mode]="'over'">
    <menus></menus>
  </mat-drawer>

  <mat-drawer-content>
    <header>
      <ng-container *ngIf="isSmallScreen">
        <button class="menu" mat-icon-button (click)="drawer.open()">
          <mat-icon>menu</mat-icon>
        </button>
        &nbsp;
      </ng-container>
      <a class="logo" routerLink="/portal">
        <img src="assets/logo.png">
      </a>
      <span class="spacer"></span>
      <a mat-icon-button href="https://pubnavi.com/support/" target="_blank">
        <mat-icon matTooltip="ヘルプ">help_outline</mat-icon>
      </a>
      <a mat-icon-button href="https://pubnavi.com/support/contact/" target="_blank">
        <mat-icon matTooltip="お問い合わせ">mail_outline</mat-icon>
      </a>
      <button class="user" mat-button [matMenuTriggerFor]="menu">
        <i class="material-icons">account_circle</i>
        <span>{{user.name}}</span>
        <span *ngIf="user.is_support">({{tenantName | async}})</span>
        <i class="material-icons">expand_more</i>
      </button>
      <mat-menu #menu="matMenu">
        <div class="tenant-name">{{tenantName | async}}</div>
        <button mat-menu-item [disabled]="user.is_support" routerLink="/portal/password">パスワード変更</button>
        <button mat-menu-item (click)="onLogoutClick()">ログアウト</button>
      </mat-menu>
    </header>

    <nav *ngIf="!isSmallScreen">
      <menus></menus>
    </nav>

    <main>
      <div class="breadcrumb" *ngIf="routeData.title === 'ホーム'">
        <span>ホーム</span>
      </div>
      <div class="breadcrumb" *ngIf="routeData.title !== 'ホーム'">
        <a routerLink="/portal">ホーム</a>
        <ng-container *ngFor="let p of routeData.parents;">
          <span>/</span>
          <a [routerLink]="[p.link]">{{p.title}}</a>
        </ng-container>
        <span>/</span>
        <span>{{routeData.title}}</span>
      </div>

      <router-outlet></router-outlet>
    </main>
  </mat-drawer-content>
</mat-drawer-container>
