import { NgModule } from '@angular/core';
import { SalesRoutesService } from './base/sales-routes.service';
import { TenantsService } from './base/tenants.service';
import { FormSettingsService } from './base/form-settings.service';
import { BanksService } from './base/banks.service';
import { BankBranchesService } from './base/bank-branches.service';
import { EbookProductCodeMappingsService } from './ebook/ebook-product-code-mappings.service';
import { EbookProductsService } from './ebook/ebook-products.service';
import { EbookRoyaltyContractsService } from './ebook/ebook-royalty-contracts.service';
import { EbookRoyaltyService } from './ebook/ebook-royalty.service';
import { EbookSalesService } from './ebook/ebook-sales.service';
import { EbookSchedulesService } from './ebook/ebook-schedules.service';
import { SharedEbookSalesImportSettingsService } from './ebook/shared-ebook-sales-import-settings.service';
import { MenusService } from './menus.service';
import { PrepaymentsService } from './payment/prepayments.service';
import { EditingCostsService } from './payment/editing-costs.service';
import { PayeesService } from './payment/payees.service';
import { PaymentRecordsService } from './payment/payment-records.service';
import { PaymentsService } from './payment/payments.service';
import { UsersService } from './users.service';
import { WorkersService } from './worker.service';
import { ScheduleDefinitionService } from './schedule/schedule-definition.service';
import { RolesService } from './roles.service';
import { EbookSalesImportSettingsService } from './ebook/ebook-sales-import-settings.service';
import { EmailSendingHistoriesService } from './email-sending-history.service';
import { NewsService } from './news.service';

@NgModule({
  declarations: [
  ],
  imports: [
  ],
  providers: [
    MenusService,
    UsersService,
    RolesService,
    WorkersService,
    // base
    BanksService,
    BankBranchesService,
    TenantsService,
    SalesRoutesService,
    FormSettingsService,
    // ebook
    EbookSalesService,
    EbookRoyaltyService,
    EbookProductsService,
    EbookProductCodeMappingsService,
    EbookRoyaltyContractsService,
    EbookSchedulesService,
    SharedEbookSalesImportSettingsService,
    EbookSalesImportSettingsService,
    // payment
    PrepaymentsService,
    EditingCostsService,
    PayeesService,
    PaymentsService,
    PaymentRecordsService,
    // schedule
    ScheduleDefinitionService,
    // email
    EmailSendingHistoriesService,
    // news
    NewsService,
  ],
})
export class ServicesModule { }
