import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response, PartialList } from './models';
import { DateUtils } from '../utils/date.utils';

export const WorkerStatus = {
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  DONE: 'DONE',
  FAILED: 'FAILED',
} as const;
type WorkerStatus = typeof WorkerStatus[keyof typeof WorkerStatus];

export const WorkerStatusText = {
  PENDING: { id: 'PENDING', text: '待機中' },
  PROCESSING: { id: 'PROCESSING', text: '処理中' },
  DONE: { id: 'DONE', text: '完了' },
  FAILED: { id: 'FAILED', text: '失敗' },
};

export interface WorkerRecord {
  id?: string;
  status?: WorkerStatus;
  process_id?: string;
  args?: string;
  enqueued_at?: Date;
  enqueued_by?: string;
  start_at?: Date;
  end_at?: Date;

  process_text?: string;
  enqueued_user_name?: string; // users.name
}

export interface WorkerFilter {
  status?: string;
  processId?: string;
  userName?: string;
  enqueuedAtFrom?: Date;
  enqueuedAtTo?: Date;
}

export interface WorkerProcessDefinition {
  process_id: string;
  name: string;
}

@Injectable()
export class WorkersService {

  constructor(
    private http: HttpClient,
  ) { }

  get(id: string): Observable<Response<WorkerRecord>> {
    return this.http.get<Response<WorkerRecord>>(`/authorized/workers/records/${id}`);
  }

  load(filter: WorkerFilter, limit: number, offset: number): Observable<Response<PartialList<WorkerRecord>>> {
    let params = new HttpParams()
      .append('status', filter.status ? filter.status : "")
      .append('process_id', filter.processId ? filter.processId : "")
      .append('user_name', filter.userName ? filter.userName : "")
      .append('enqueued_at_from', filter.enqueuedAtFrom ? DateUtils.yyyymmdd(filter.enqueuedAtFrom) : "")
      .append('enqueued_at_to', filter.enqueuedAtTo ? DateUtils.yyyymmdd(filter.enqueuedAtTo) : "")
      .append('limit', String(limit))
      .append('offset', String(offset));
    return this.http.get<Response<PartialList<WorkerRecord>>>(`/authorized/workers/records`, { params });
  }

  getProcessDefs(): Observable<Response<WorkerProcessDefinition[]>> {
    return this.http.get<Response<WorkerProcessDefinition[]>>(`/authorized/workers/process/defs`)
  }

}