<form [formGroup]="form" autocomplete="off">

  <ng-container *ngIf="result != 'Succeeded'">
    <div class="entry">
      <p>
        メールに記載されているパスワード再設定用のコードを入力して、新しいパスワードを設定してください。
        <br>
        <span>※しばらくしてもメールが届かない場合は、メールが迷惑メールフォルダに振り分けられていないか等をご確認ください。</span>
        <br>
        <span>※パスワードは８文字以上で、半角英字・半角数字・記号を各１文字以上含めてください。</span>
      </p>

      <mat-form-field appearance="outline">
        <mat-label>検証コード</mat-label>
        <input matInput placeholder="メールに記載されている検証コード" type="text" formControlName="code" autofocus>
        <mat-error *ngIf="code.hasError('required')">検証コードを入力してください</mat-error>
        <mat-error *ngIf="code.hasError('invalid')">有効な検証コードを入力してください</mat-error>
        <mat-error *ngIf="code.hasError('expired')">検証コードの有効期限が切れています</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>メールアドレス</mat-label>
        <input matInput placeholder="メールアドレス" formControlName="email" spellcheck="false">
        <mat-error *ngIf="email.hasError('required')">メールアドレスを入力してください</mat-error>
        <mat-error *ngIf="email.hasError('email')">正しい形式のメールアドレスを入力してください</mat-error>
        <mat-error *ngIf="email.hasError('invalid')">有効なメールアドレスを入力してください</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>パスワード</mat-label>
        <input matInput placeholder="パスワード" [type]="passwordVisible ? 'text' : 'password'" formControlName="password">
        <a mat-icon-button matSuffix (click)="passwordVisible = !passwordVisible">
          <mat-icon>{{passwordVisible ? 'visibility' : 'visibility_off'}}</mat-icon>
        </a>
        <mat-error *ngIf="password.hasError('required')">パスワードを入力してください</mat-error>
        <mat-error *ngIf="password.hasError('invalid')">要件を満たすパスワードを入力してください</mat-error>
      </mat-form-field>

      <p class="warning" *ngIf="result == 'Failed'">
        リクエストを受け付けることができませんでした<br>管理者へお問い合わせください
      </p>
    </div>

    <div class="action">
      <span></span>
      <button mat-raised-button color="primary" [disabled]="processing" (click)="onSubmit()">更新</button>
    </div>
  </ng-container>

  <ng-container *ngIf="result == 'Succeeded'">
    <div class="entry">
      <span></span>
      <p>
        パスワードを更新しました。
        <br>
        次回より新しいパスワードでログインしてください。
      </p>
      <a mat-button color="primary" (click)="onGoToLoginClick()">ログイン画面ヘ</a>
      <span></span>
    </div>
  </ng-container>
</form>